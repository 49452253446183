.formField {
  margin: 0rem 0 1.5rem;
  label {
    display: block;
    margin: 0.3rem 0 0.8rem;
    padding: 0 0.5rem;
    letter-spacing: 0.2px;
  }
}

.loginFormWrapper {
  width: 100%;
  max-width: 400px;
  padding: 0;
  background: var(--sidebar-background);
  border: 1px solid hsla(0, 0%, 0%, 0.1);
  border-radius: 6px;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.loginFormHeader {
  padding: 1.5rem 2.4rem 0;
  img {
    display: block;
    max-width: 3.2rem;
    margin: 0 auto 2.0rem;
    border-radius: 5px;
  }
  h1 {
    font-size: 1.3rem;
    padding: 0 0.5rem;
  }
}

.loginFormBody {
  padding: 1.0rem 2.4rem 1.5rem;
}

.loginFormWrapper input {
  width: 100%;
  font-size: 16px;
  padding: 12px 16px 12px 16px;
  background: var(--background_a3);
  border-radius: 5px;
  border: 1px solid var(--background_a3);
  transition: border 100ms ease;

  &:focus {
    outline: none;
    border: 1px solid var(--sidebar-text);
  }
}

.loginFormWrapper button {
  width: 100%;
  display: block;
  margin-top: 9px;
  font-size: 16px;
  padding: 14px 14px 14px 14px;
  background: var(--sidebar-text);
  color: var(--background);
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;
}

.loginActions {
  margin: 2.2rem 0 0.4rem;
  a {
    color: var(--sidebar-text);
  }
}

.OAuthProviders {
  display: flex;
  flex-direction: column;
}

.OAuthProviders a {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 9px;
  font-size: 16px;
  padding: 12px 12px 12px 12px;
  border-radius: 5px;
  border: 1px solid transparent;
  border: 1px solid transparent;
  text-align: center;
  justify-content: center;
  color: var(--action-button-color);
  svg {
    max-width: 1.5rem;
    max-height: 1.5rem;
    width: 100%;
    height: 100%;
    margin-right: 0.5rem;
    fill: currentColor;
  }
  &:hover {
    border-color: var(--action-color);
  }
}

.githubButton {
  background: hsla(0, 0%, 0%, 1.0);
  &:hover {
    background: hsla(0, 0%, 10%, 1.0);
  }
}

.appleButton {
  background: hsla(0, 0%, 0%, 1.0);
  &:hover {
    background: hsla(0, 0%, 10%, 1.0);
  }
}

.separator {
  display: block;
  text-align: center;
  padding: 1rem 0;
}
