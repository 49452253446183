.invoice-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--background_a5);
  padding: 0.4rem 0;

  .date {
    opacity: 0.7;
    font-size: 0.9325rem;
  }

  .price {
    margin-right: 0.8rem;
    font-size: 0.9325rem;
  }

  .paid-with-card {
    opacity: 0.7;
    margin-right: 0.8rem;
    font-size: 0.9325rem;
  }
}
