.workspaceIcon {
  position: absolute;
  left: 0.1rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 4px;
  border: 1px solid var(--background_a5);
  text-transform: uppercase;

  &:global {
    &.enlarged {
      width: 2.8rem;
      height: 2.8rem;
    }
  }
}

.workspaceTitleWrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0 0 1.6rem;
  width: 100%;
  max-width: 134px;

  &:global {
    &.enlarged {
      padding-left: 3.6rem;
      height: 2.8rem;
      max-width: 100%;
    }
  }

  svg {
    display: none;
    position: absolute;
    top: 3px;
    left: 0.5rem;
    width: 1.6rem;
  }

  img {
    position: absolute;
    left: 0.1rem;
    width: 1.4rem;
    height: 1.4rem;
    margin: 0;
    padding: 0;
    border-radius: 4px;
  }
}

.workspaceName {
  display: block;
  margin-left: 0.3rem;
  &:global {
    &.withEllipsis {
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
