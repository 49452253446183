.select-image-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  color: hsla(0, 0%, 100%, 0.99);
  background: hsla(0, 0%, 0%, 0.5);
  border-radius: 5px;
}

.select-image {
  display: block;
  position: relative;

  width: 240px;
  max-width: 100%;
  max-height: 100%;

  border-radius: 5px;

  background: var(--background_a5);

  box-shadow: 0 0 2px 0 hsla(0, 0%, 0%, 0.5);

  transition: all 200ms ease;

  &:hover {
    box-shadow: 0 0 4px 4px hsla(0, 0%, 0%, 0.2);
  }

  img {
    display: block;
  }
}

.select-image-wrapper {
  display: block;
  min-height: 180px;
  min-width: 180px;
  max-width: 100%;
  max-height: 100%;

  img {
    display: block;
    border-radius: 5px;
    max-width: 100%;
  }
}

.custom-dropzone {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  border-radius: 5px;
}

.avatar-caption {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;

  z-index: 1;

  font-size: 15px;
  font-weight: 500;

  text-align: center;

  background: hsla(0, 0%, 0%, 0.5);
  border-radius: 5px;
  color: white;
  margin: 0;

  opacity: 0;
  transform: scale(1.05);

  transition: all 360ms ease;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  &.is-ready {
    opacity: 0.5;
    transform: scale(1);
    outline: none;
  }

  &:hover,
  &:focus {
    opacity: 1;
    transform: scale(1);
    outline: none;
  }
}

.image-editor-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  max-width: 640px;
  margin: auto;

  padding: 1.0rem;

  background: var(--background_a5);
  border-radius: 5px;

  box-shadow: 0 0 0 1px rgba(136, 152, 170, .1),
              0 15px 35px 0 rgba(49, 49, 93, .1),
              0 5px 15px 0 rgba(0, 0, 0, .08);
}

.image-editor-slider {
  display: block;
  margin: 1.5rem auto 1.0rem;
  width: 90%;

  -webkit-appearance: none;
  appearance: none;
  height: 0.3rem;
  background: var(--sidebar-background_m_3);
  border-radius: 5px;
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: 0; /* Centers thumb on the track */
    background-color: var(--sidebar-text);
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
  }

  &::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    margin-top: 0; /* Centers thumb on the track */
    background-color: var(--sidebar-text);
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
  }

  &:focus {
    outline: none;
  }

  /***** Chrome, Safari, Opera, and Edge Chromium *****/
  &:active::-webkit-slider-thumb {
    opacity: 0.8;
  }

  /******** Firefox ********/
  &:active::-moz-range-thumb {
    opacity: 0.8;
  }
}

.image-editor-controls {
}

.image-editor-actions {
  text-align: center;
  margin-top: 2.0rem;
  padding: 0 0 0.8rem;
  a,
  button {
    display: inline-block;
    margin: 0 0.6rem;
    font-size: 14px;
    text-decoration: none;
  }
}
