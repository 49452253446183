.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: var(--background);
  padding: 1rem 2.0rem;
}

.message {
  width: 100%;
  max-width: 640px;
  pre {
    background: var(--sidebar-background);
    padding: 1.0rem;
    overflow: auto;
    border-radius: 5px;
    font-family: Menlo, Monaco, monospace;
    font-size: 12px;
    line-height: 1.3;
  }
  code {
    opacity: 0.7;
  }
  details {
    margin-top: 1.5rem;
  }
}

.reloadButton {
  font-size: 1rem;
}
