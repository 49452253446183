:root {
  --background: hsla(0, 0%, 12%, 1.0);

  --processed-color: hsl(120, 61.57%, 44.9%);
  --processing-color: hsl(60, 84.33%, 43.4%);

  --editing-locale-select-height: 58px;
  --view-header-height: 66px;
  --menu-width: 272px;
  --menu-height: 2.8rem;
  --secondary-menu-width: 200px;
  --narrow-width: 640px;
  --container-width: 1140px;
  --footer-width: 1100px;

  --content-width: 960px;
  --post-width: 720px;
  --home-width: 740px;

  --medium-breakpoint: 1024px;
  --wide-breakpoint: 1280px;
  --widest-breakpoint: 1650px;

  --menu-background-color: hsla(0, 0%, 16%, 1.0);
  --secondary-menu-background-color: hsla(0, 0%, 10%, 1.0);

  // --menu-background-color: var(--background-m-2);
  // --dropdown-background-color: var(--background-m-3);
  // --label-color: var(--background-m-5);

  // --label-color: var(--background-m-5);
  // --input-border-color: var(--background-m-3);
  --input-border-color-hover: hsla(0, 0%, 20%, 1.0);
  // --input-border-color-focus: var(--background-m-5);

  --menu-width: 240px;

  /* themes */
  --background-color: hsl(0, 0%, 98%);
  --text-color: #121416d8;
  --link-color: #543fd7;
  --menu-border-color: hsla(0, 0%, 50%, 0.1);
  --menu-link-color: var(--link-color);

  // cover
  --cover-background-color: hsl(47, 100%, 99%);
  --cover-side-color: hsl(47, 100%, 99%);
  --gradient1: hsl(0, 13%, 97%);
  --gradient2: hsl(0, 100%, 99%);
  --yellow-bg: hsl(38, 9%, 94%);
  --yellow-bg1: hsl(38, 4%, 91%);

  // aldarkose
  --background: #fafafa;
  --text: #000000;
  --sidebar-background: #ececec;
  --sidebar-text: #2134ff;
  --accent: #555555;
  --text-on-accent: #ffffff;
  --action: #999999;
  --background_is_light: true;
  --background_darker: hsl(0, 0%, 95%);
  --background_darker_1: hsl(0, 0%, 89%);
  --background_darker_2: hsl(0, 0%, 80%);
  --background_lighter: hsl(0, 0%, 83%);
  --background_lighter_1: hsl(0, 0%, 89%);
  --background_lighter_2: hsl(0, 0%, 98%);
  --background_alt: hsl(0, 0%, 95%);
  --background_alt_1: hsl(0, 0%, 92%);
  --background_alt_2: hsl(0, 0%, 83%);
  --background_a3: hsl(0, 0%, 95%);
  --background_a5: hsl(0, 0%, 90%);
  --background_a10: hsl(0, 0%, 80%);
  --background_a20: hsl(0, 0%, 60%);
  --background_a30: hsl(0, 0%, 30%);
  --background_l5: hsl(0, 0%, 35%);
  --background_l10: hsl(0, 0%, 45%);
  --background_d5: hsl(0, 0%, 40%);
  --background_d10: hsl(0, 0%, 30%);
  --background_b5: hsl(0, 0%, 35%);
  --background_b10: hsl(0, 0%, 45%);
  --background_ds10: hsl(0, 0%, 45%);
  --background_m_0: hsl(0, 0%, 45%);
  --background_m_1: hsl(0, 0%, 62%);
  --background_m_2: hsl(0, 0%, 78%);
  --background_m_3: hsl(0, 0%, 95%);
  --background_m_4: hsl(0, 0%, 12%);
  --background_m_5: hsl(0, 0%, 28%);
  --background_t_0: hsl(0, 0%, 45%);
  --background_t_1: hsl(0, 0%, 45%);
  --background_t_2: hsl(0, 0%, 45%);
  --text_is_light: false;
  --text_a3: hsl(0, 0%, 3%);
  --text_a5: hsl(0, 0%, 8%);
  --text_a10: hsl(0, 0%, 18%);
  --text_a20: hsl(0, 0%, 38%);
  --text_a30: hsl(0, 0%, 68%);
  --text_l5: hsl(0, 0%, 73%);
  --text_l10: hsl(0, 0%, 83%);
  --text_d5: hsl(0, 0%, 78%);
  --text_d10: hsl(0, 0%, 68%);
  --text_b5: hsl(0, 0%, 73%);
  --text_b10: hsl(0, 0%, 83%);
  --text_ds10: hsl(0, 0%, 83%);
  --text_m_0: hsl(0, 0%, 83%);
  --text_m_1: hsl(0, 0%, 99%);
  --text_m_2: hsl(0, 0%, 16%);
  --text_m_3: hsl(0, 0%, 33%);
  --text_m_4: hsl(0, 0%, 49%);
  --text_m_5: hsl(0, 0%, 66%);
  --text_t_0: hsl(0, 0%, 83%);
  --text_t_1: hsl(0, 0%, 83%);
  --text_t_2: hsl(0, 0%, 83%);
  --sidebar-background_is_light: true;
  --sidebar-background_a3: hsl(0, 0%, 90%);
  --sidebar-background_a5: hsl(0, 0%, 85%);
  --sidebar-background_a10: hsl(0, 0%, 75%);
  --sidebar-background_a20: hsl(0, 0%, 55%);
  --sidebar-background_a30: hsl(0, 0%, 25%);
  --sidebar-background_l5: hsl(0, 0%, 30%);
  --sidebar-background_l10: hsl(0, 0%, 40%);
  --sidebar-background_d5: hsl(0, 0%, 35%);
  --sidebar-background_d10: hsl(0, 0%, 25%);
  --sidebar-background_b5: hsl(0, 0%, 30%);
  --sidebar-background_b10: hsl(0, 0%, 40%);
  --sidebar-background_ds10: hsl(0, 0%, 40%);
  --sidebar-background_m_0: hsl(0, 0%, 40%);
  --sidebar-background_m_1: hsl(0, 0%, 56%);
  --sidebar-background_m_2: hsl(0, 0%, 73%);
  --sidebar-background_m_3: hsl(0, 0%, 90%);
  --sidebar-background_m_4: hsl(0, 0%, 6%);
  --sidebar-background_m_5: hsl(0, 0%, 23%);
  --sidebar-background_t_0: hsl(0, 0%, 40%);
  --sidebar-background_t_1: hsl(0, 0%, 40%);
  --sidebar-background_t_2: hsl(0, 0%, 40%);
  --sidebar-text_is_light: false;
  --sidebar-text_a3: hsl(235, 100%, 59%);
  --sidebar-text_a5: hsl(235, 100%, 64%);
  --sidebar-text_a10: hsl(235, 100%, 74%);
  --sidebar-text_a20: hsl(235, 100%, 94%);
  --sidebar-text_a30: hsl(0, 0%, 100%);
  --sidebar-text_l5: hsl(0, 0%, 100%);
  --sidebar-text_l10: hsl(0, 0%, 100%);
  --sidebar-text_d5: hsl(0, 0%, 95%);
  --sidebar-text_d10: hsl(0, 0%, 85%);
  --sidebar-text_b5: hsl(0, 0%, 90%);
  --sidebar-text_b10: hsl(0, 0%, 100%);
  --sidebar-text_ds10: hsl(0, 0%, 100%);
  --sidebar-text_m_0: hsl(0, 0%, 100%);
  --sidebar-text_m_1: hsl(0, 0%, 17%);
  --sidebar-text_m_2: hsl(0, 0%, 33%);
  --sidebar-text_m_3: hsl(0, 0%, 50%);
  --sidebar-text_m_4: hsl(0, 0%, 67%);
  --sidebar-text_m_5: hsl(0, 0%, 83%);
  --sidebar-text_t_0: hsl(0, 0%, 100%);
  --sidebar-text_t_1: hsl(0, 0%, 100%);
  --sidebar-text_t_2: hsl(0, 0%, 100%);
  --accent_is_light: false;
  --accent_a3: hsl(0, 0%, 36%);
  --accent_a5: hsl(0, 0%, 41%);
  --accent_a10: hsl(0, 0%, 51%);
  --accent_a20: hsl(0, 0%, 71%);
  --accent_a30: hsl(0, 0%, 100%);
  --accent_l5: hsl(0, 0%, 100%);
  --accent_l10: hsl(0, 0%, 100%);
  --accent_d5: hsl(0, 0%, 95%);
  --accent_d10: hsl(0, 0%, 85%);
  --accent_b5: hsl(0, 0%, 90%);
  --accent_b10: hsl(0, 0%, 100%);
  --accent_ds10: hsl(0, 0%, 100%);
  --accent_m_0: hsl(0, 0%, 100%);
  --accent_m_1: hsl(0, 0%, 17%);
  --accent_m_2: hsl(0, 0%, 33%);
  --accent_m_3: hsl(0, 0%, 50%);
  --accent_m_4: hsl(0, 0%, 67%);
  --accent_m_5: hsl(0, 0%, 83%);
  --accent_t_0: hsl(0, 0%, 100%);
  --accent_t_1: hsl(0, 0%, 100%);
  --accent_t_2: hsl(0, 0%, 100%);
  --text-on-accent_is_light: true;
  --text-on-accent_a3: hsl(0, 0%, 97%);
  --text-on-accent_a5: hsl(0, 0%, 92%);
  --text-on-accent_a10: hsl(0, 0%, 82%);
  --text-on-accent_a20: hsl(0, 0%, 62%);
  --text-on-accent_a30: hsl(0, 0%, 32%);
  --text-on-accent_l5: hsl(0, 0%, 37%);
  --text-on-accent_l10: hsl(0, 0%, 47%);
  --text-on-accent_d5: hsl(0, 0%, 42%);
  --text-on-accent_d10: hsl(0, 0%, 32%);
  --text-on-accent_b5: hsl(0, 0%, 37%);
  --text-on-accent_b10: hsl(0, 0%, 47%);
  --text-on-accent_ds10: hsl(0, 0%, 47%);
  --text-on-accent_m_0: hsl(0, 0%, 47%);
  --text-on-accent_m_1: hsl(0, 0%, 64%);
  --text-on-accent_m_2: hsl(0, 0%, 80%);
  --text-on-accent_m_3: hsl(0, 0%, 97%);
  --text-on-accent_m_4: hsl(0, 0%, 14%);
  --text-on-accent_m_5: hsl(0, 0%, 30%);
  --text-on-accent_t_0: hsl(0, 0%, 47%);
  --text-on-accent_t_1: hsl(0, 0%, 47%);
  --text-on-accent_t_2: hsl(0, 0%, 47%);
  --action_is_light: true;
  --action_a3: hsl(0, 0%, 57%);
  --action_a5: hsl(0, 0%, 52%);
  --action_a10: hsl(0, 0%, 42%);
  --action_a20: hsl(0, 0%, 22%);
  --action_a30: hsl(0, 0%, 0%);
  --action_l5: hsl(0, 0%, 5%);
  --action_l10: hsl(0, 0%, 15%);
  --action_d5: hsl(0, 0%, 10%);
  --action_d10: hsl(0, 0%, 0%);
  --action_b5: hsl(0, 0%, 5%);
  --action_b10: hsl(0, 0%, 15%);
  --action_ds10: hsl(0, 0%, 15%);
  --action_m_0: hsl(0, 0%, 15%);
  --action_m_1: hsl(0, 0%, 32%);
  --action_m_2: hsl(0, 0%, 48%);
  --action_m_3: hsl(0, 0%, 65%);
  --action_m_4: hsl(0, 0%, 82%);
  --action_m_5: hsl(0, 0%, 98%);
  --action_t_0: hsl(0, 0%, 15%);
  --action_t_1: hsl(0, 0%, 15%);
  --action_t_2: hsl(0, 0%, 15%);
}

button {
  text-align: center;
  border: 0;
  box-shadow: none;
  background: none;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

html[data-theme='white'] {
  --background-color: hsl(0, 0%, 98%);
  --link-color: #543fd7;
  --menu-background-color: hsla(240, 5%, 93%, 1.0);
  --menu-background-gradient-1: hsl(0, 0%, 93%, 0);
  --menu-background-gradient-2: hsla(0, 0%, 93%, 1.0);
  --menu-border-color: hsla(0, 0%, 50%, 0.1);
  --menu-link-color: hsl(0, 0%, 22%);
  --bordered-color: hsla(0, 0%, 50%, 0);

  --dot-background: linear-gradient(90deg, hsla(0, 0%, 96%, 1) 15px, transparent 1%) 50%, linear-gradient(hsla(0, 0%, 96%) 15px,transparent 1%) 50%, hsla(0, 0%, 0%, 0.30);

  --text-color: hsla(0, 0%, 21%, 1.0);
  --caption-color: hsla(0, 0%, 37%, 1.0);
  --action-color: hsla(224, 100%, 56%, 1);
  --action-color-hover: hsla(224, 100%, 40%, 1);
  --box-shadow: 0 2px 10px hsla(0, 0%, 0%, 0.06);

  --logo-color: hsla(257.41, 95.12%, 59.04%, 1);

  --action-button-color: hsla(0, 0%, 97%, 1.0);
  --action-button-background-color: hsla(233.16, 100%, 62%, 1);
  --action-button-background-color-hover: hsla(233.16, 100%, 52%, 1);

  --ui-button-color: hsla(0, 0%, 19%, 1.0);
  --ui-button-border-color: hsla(0, 0%, 88%, 0.95);
  --ui-button-background-color: hsla(0, 0%, 98%, 0.95);
  --ui-button-background-color-hover: hsla(0, 0%, 90%, 0.95);
  --ui-button-background-color-active: hsla(0, 0%, 90%, 0.95);

  --hero-background-color-one: hsl(0, 0%, 100%);
  --hero-background-color-two: hsl(0, 0%, 94%);
  --hero-background-color-three: hsla(0, 0%, 100%, 0.59);
  --hero-background: linear-gradient(180deg, rgb(255, 255, 255), rgb(239, 239, 239) 98.32%);
  --hero-slate-background: linear-gradient(92deg, hsl(240, 0%, 90.26%) 10%, hsl(240, 0%, 95.9%) 68%);

  --h2-color: hsla(0, 0%, 55%, 1.0);
  --heading-color: hsla(0, 0%, 5%, 0.98);
  --heading-gradient-background: linear-gradient(360deg, hsl(0, 0%, 15%), hsl(0, 0%, 33%));
  --brand-gradient: linear-gradient(12deg, hsl(248.94, 49.43%, 48.73%) 10%, hsl(264.26, 63.81%, 57.5%) 44.96%, hsl(262.34, 87.28%, 55.65%) 68.49%);
  --gradient3: linear-gradient(12deg, hsla(275.74, 89.95%, 64.27%, 0.53) 10.5%, hsla(283.4, 100%, 70%, 0.53) 44.96%, hsla(310.21, 100%, 60.77%, 0.35) 68.49%);
  --gradient4: linear-gradient(12deg, hsla(271.91, 89.95%, 64.27%, 0.32) 10.5%, hsla(285.32, 100%, 70%, 0.35) 44.96%, hsla(298.72, 100%, 60.77%, 0.35) 68.49%);
  --gradient5: linear-gradient(to bottom right, #6f12cc, #4f41ee, #8330e2);
  --gradient6: transparent;
  --gradient7: radial-gradient(circle at 50% 50%,rgba(213,212,255,0.06) 28.99%,hsla(0,0%,98%,0)),linear-gradient(to top, rgba(166, 127, 255, 0.01), rgba(223, 204, 255, 0.04) 24.79%, hsla(0, 0%, 98%, 0) 84.87%);
  --gradient8: linear-gradient(hsla(234, 0%, 100%, 0), hsla(285.32, 95.25%, 78.42%, 0.08)),radial-gradient(circle at 10% 20%, rgba(120, 0, 225, 0), rgba(255, 255, 255, 0.16) 59.66%);
  --gradient9: linear-gradient(to top, hsla(266, 100%, 50%, 0.06), hsla(234, 90%, 21%, 0));
  --gradient10: linear-gradient(109deg, hsla(256.6, 100%, 85.38%, 0.12), hsla(285.32, 100%, 41.54%, 0)),radial-gradient(circle at 10% 20%, rgba(117, 1, 218, 0.03), rgba(255, 255, 255, 0.06));
  --gradient11: linear-gradient(214deg, hsla(266, 100%, 60%, 0.07), hsla(234, 90%, 31%, 0));
  --gradient12: linear-gradient(290deg, rgba(255, 255, 255, 0), rgba(106, 0, 245, 0.13));

  --cta-background-color: hsla(0, 0%, 100%, 1.0);
  --cta-button-background: linear-gradient(12deg, hsl(235.53, 49.43%, 48.73%) 10%, hsl(244, 63.81%, 57.5%) 44.96%, hsl(256, 87.28%, 55.65%) 68.07%);
  --cta-background: linear-gradient(180deg, hsl(0, 0%, 4%), hsl(0, 0%, 10%) 98.32%);
  --cta-border-color: hsla(0, 0%, 88%, 1.0);
  --cta-color: hsla(0, 0%, 95%, 1.0);
  --cta-gradient: linear-gradient(92deg, hsl(240, 3%, 15%) 10%, hsl(240, 3%, 8%) 98%);

  --app-section-background: linear-gradient(80deg, hsla(0, 0%, 93%, 0.1), hsla(0, 0%, 100%, 0.1) 98.32%);

  --code-background-color: hsla(0, 0%, 91%, 1.0);

  --contrast-background-color: hsla(0, 0%, 100%, 1);
  --contrast-color: hsla(0, 0%, 9%, 1.0);

  --grid-item-background-color: hsla(0, 0%, 100%, 1);
  --grid-item-color: hsla(0, 0%, 9%, 1.0);

  --checkbox-background-color: hsl(244, 62%, 85%);
  --checkbox-background-color-hover: hsla(244, 0%, 80%, 0.2);
  --checkbox-background-color-active: hsl(244, 0%, 88%);
  --checkbox-tick-color: hsl(244, 0%, 28.5%);
  --checkbox-tick-color-active: hsl(224, 100%, 12%);

  --link-color: hsla(0, 0%, 25%, 1.0);
  --link-color-hover: hsla(0, 0%, 6%, 1.0);
  --link-color-active: hsla(0, 0%, 1%, 1.0);

  --modal-background-color: hsla(229.43, 0%, 100%, 1);

  --em-background-color: hsla(61.55, 93.52%, 61.43%, 1);
  --em-color: inherit;

  --highlight-background-color: hsla(246.22, 0%, 95.25%, 0.9);
  --highlight-border-color: hsla(0, 0%, 93%, 1.0);

  --tasks-background-color: hsla(0, 0%, 97%, 1.0);
  --tasks-item-background-color: hsla(0, 0%, 100%, 1.0);
  --float-background-color: hsla(0, 0%, 100%, 1.0);
  --float-border-color: hsla(0, 0%, 91%, 1.0);
  --sidebar-background-color: hsla(0, 0%, 97%, 1.0);
  --commander-background-color: hsla(0, 0%, 99%, 0.95);
  --commander-status-background-color: hsla(0, 0%, 99%, 0.95);
  --toolbar-background-color: hsla(0, 0%, 96%, 0.92);
  --background-color: hsla(229.29, 2.04%, 95%, 1.0);
  --mute-background-color: hsl(229.29, 2.04%, 94.37%);
  --overlay-background-color: hsla(0, 0%, 95%, 0.95);
  --dropdown-background-color: hsla(211, 10%, 99%, 0.92);
  --dropdown-border-color: hsla(0, 0%, 93%, 1.0);
  --tippy-background-color: hsla(211, 10%, 97%, 1.0);

  --tab-background-color: hsla(0, 0%, 97%, 1.0);
  --tab-background-color-hover: hsla(0, 0%, 94%, 1.0);
  --tab-background-color-active: hsla(0, 0%, 91%, 1.0);

  --tab-border-color: hsla(0, 0%, 93%, 1.0);

  --tab-color: hsla(0, 0%, 25%, 1.0);
  --tab-color-hover: hsla(0, 0%, 6%, 1.0);
  --tab-color-active: hsla(0, 0%, 1%, 1.0);

  --translucent-background-color: hsla(0, 0%, 100%, 0.3);
  --translucent-border-color: hsla(0, 0%, 0%, 0.2);
  --bright-border-color: hsla(255.54, 93.24%, 47.35%, 0.13);
  --pale-border-color: hsla(255.54, 100%, 73%, 0.12);
  --border-color: hsla(0, 0%, 92%, 1.0);

  --pale-box-shadow: 0 0 20px -5px hsla(272.33, 100%, 35.25%, 0.1);

  --input-color: hsla(0, 0%, 2%, 1.0);
  --input-label-color: hsla(0, 0%, 70%, 1.0);
  --input-border-color: hsla(213, 11%, 84%, 1.0);
  --input-border-color-focus: hsla(224, 100%, 56%, 1);
  --input-background-color: hsl(229.29, 2.04%, 98%);
  --input-background-color-focus: hsl(229.29, 2.04%, 100%);
  --input-placeholder-color: #777;
  --input-color-error: hsl(354, 66%, 52%);
  --input-border-color-error: hsl(354, 66%, 52%);

  --search-background-color: hsl(240, 5%, 96%);
  --search-background-color-hover: hsl(240, 5%, 86%);
  --search-border-color: hsl(240, 3%, 90%);
  --search-border-color-hover: hsl(240, 3%, 82%);

  --sidebar-link-color: hsla(204, 100%, 10%, 1.0);
  --sidebar-link-color-hover: hsla(0, 0%, 6%, 1.0);
  --sidebar-link-color-active: hsla(0, 0%, 1%, 1.0);
  --sidebar-link-background-color: hsla(0, 0%, 100%, 0);
  --sidebar-link-background-color-active: hsla(0, 0%, 88%, 1.0);

  --commander-link-color: hsla(0, 0%, 25%, 1.0);
  --commander-link-color-hover: hsla(0, 0%, 6%, 1.0);
  --commander-link-color-active: hsla(0, 0%, 1%, 1.0);
  --commander-link-background-color: hsla(0, 0%, 100%, 0);
  --commander-link-background-color-active: hsla(0, 0%, 88%, 1.0);

  --footer-link-color: hsla(204, 100%, 10%, 1.0);
  --footer-link-color-hover: hsla(0, 0%, 6%, 1.0);
  --footer-link-color-active: hsla(0, 0%, 1%, 1.0);

  --menu-cta-background: linear-gradient(12deg, hsl(235.53, 49.43%, 48.73%) 10%, hsl(244, 63.81%, 57.5%) 44.96%, hsl(256, 87.28%, 55.65%) 68.07%);
  --menu-background-color: hsla(211, 10%, 97%, 0.93);
  --menu-border-color: hsla(0, 0%, 93%, 1.0);
  --menu-link-color: hsla(0, 0%, 21%, 1.0);
  --menu-link-color-active: hsla(0, 0%, 16%, 1.0);
  --menu-link-background-color: hsla(0, 0%, 95%, 1.0);
  --menu-link-background-color-active: hsla(0, 0%, 88%, 1.0);

  --offering-item-background-color: hsla(0, 0%, 100%, 1);
  --offering-item-color: hsla(0, 0%, 2%, 1.0);
  --offering-item-border-color: hsla(0, 0%, 88%, 1.0);
  --offering-item-border-color-active: hsla(233.16, 100%, 62%, 1);

  --payment-background-color: hsla(0, 0%, 96%, 0.95);

  --invoice-page-background-color: hsla(0, 0%, 94%, 1.0);
  --invoice-background-color: hsla(0, 0%, 100%, 1.0);

  --secondary-menu-background-color: hsla(211, 13%, 98%, 0.88);
  --secondary-menu-border-color: hsla(0, 0%, 93%, 1.0);
  --secondary-menu-link-color: hsla(0, 0%, 21%, 1.0);
  --secondary-menu-link-color-active: hsla(0, 0%, 16%, 1.0);
  --secondary-menu-link-background-color: hsla(0, 0%, 100%, 0);
  --secondary-menu-link-background-color-active: hsla(0, 0%, 88%, 1.0);

  --list-link-color: hsla(0, 0%, 15%, 1.0);
  --list-link-color-active: hsla(0, 0%, 6%, 1.0);
  --list-link-background-color: hsla(0, 0%, 100%, 0);
  --list-link-background-color-active: hsla(0, 0%, 88%, 1.0);

  --list-background-color: hsla(0, 0%, 95%, 0.95);
  --list-border-color: hsla(0, 0%, 86%, 0.8);
  --list-header-background-color: hsla(0, 0%, 98%, 0.9);

  --banner-background-color: hsla(211, 13%, 93%, 0.88);
  --banner-border-color: hsla(0, 0%, 88%, 1.0);

  --notification-background-color: hsla(229.29, 2.04%, 92%, 0.88);
  --notification-color: hsla(0, 0%, 10%, 1.0);

  --notice-color: hsl(0deg 0% 43%);
  --notice-background-color: hsl(0deg 0% 91%);
  --notice-border-color: hsl(0deg 0% 88%);

  --notice-warning-color: hsl(53deg 77% 27%);
  --notice-warning-background-color: hsl(50deg 62% 75%);
  --notice-warning-border-color: hsl(53deg 74% 44% / 18%);

  --notice-info-color: hsl(191deg 80% 38%);
  --notice-info-background-color: hsl(191deg 100% 94%);
  --notice-info-border-color: hsl(191deg 79% 44% / 55%);

  --info-background-color: hsla(0, 0%, 97%, 0.5);
  --info-background-color2: hsla(253.68, 24.22%, 27.77%, 0.31);
  --info-background-color3: hsla(253.68, 0%, 100%, 0.65);
  --info-border-color: hsla(255.54, 100%, 44%, 0);

  --info-block-background-color: linear-gradient(360deg, hsl(225, 0%, 21.15%), hsla(225, 0%, 27.31%, 0.82));
  --info-block-color: hsla(0, 0%, 90%, 1.0);
  --info-block-box-shadow: 0 13px 40px hsla(0, 0%, 90.5%, 0.3), 0 4px 4px hsla(0, 0%, 76%, 0.2);

  --mark-color: inherit;
  --mark-background-color: hsl(50, 86.01%, 57.11%);

  --gradient-one: linear-gradient(92deg, hsl(240, 3%, 94%) 10%, hsl(240, 3%, 84%) 68%);
  --gradient-two: linear-gradient(92deg, hsl(240, 3%, 94%) 10%, hsl(240, 3%, 84%) 68%);

  --screaming-color: hsl(257, 93%, 53%);

  --dot-background: linear-gradient(90deg, hsl(240, 3%, 94%) 15px, transparent 1%) 50%, linear-gradient(hsl(240, 3%, 94%) 15px, transparent 1%) 50%, hsla(0, 0%, 0%, 0.30);
}

html[data-theme='black'] {
  --background-color: black;
  --link-color: #828fff;
  --menu-background-gradient-1: hsla(240, 5%, 7%, 0);
  --menu-background-gradient-2: hsla(240, 5%, 7%, 1.0);
  --menu-border-color: hsla(0, 0%, 50%, 0.1);
  --menu-link-color: hsl(0, 0%, 78%);

  --bordered-color: hsla(0, 0%, 50%, 0.2);

  --dot-background: linear-gradient(90deg, hsla(0, 0%, 2%, 1) 15px, transparent 1%) 50%, linear-gradient(hsla(0, 0%, 2%) 15px,transparent 1%) 50%, hsla(0, 0%, 100%, 0.16);

  --text-color: hsla(0, 0%, 78%, 1.0);
  --caption-color: hsla(0, 0%, 63%, 1.0);
  --action-color: hsla(249.95, 100%, 65%, 1);
  --action-color-hover: hsla(249.95, 100%, 60%, 0.8);
  --box-shadow: 0 2px 10px hsla(0, 0%, 0%, 0.06);

  --logo-color: hsla(255, 77%, 82%, 1.000);

  --action-button-background-color: hsla(249.95, 100%, 56%, 1);
  --action-button-color: hsla(0, 0%, 78%, 1.0);
  --action-button-background-color-hover: hsla(249.95, 100%, 56%, 0.8);

  --ui-button-color: hsla(0, 0%, 81%, 1.0);
  --ui-button-border-color: hsla(0, 0%, 16%, 0.95);
  --ui-button-background-color: hsla(0, 0%, 10%, 0.95);
  --ui-button-background-color-hover: hsla(0, 0%, 20%, 0.95);
  --ui-button-background-color-active: hsla(0, 0%, 20%, 0.95);

  --hero-background-color-one: hsl(0, 0%, 11%);
  --hero-background-color-two: hsl(0, 0%, 5%);
  --hero-background-color-three: hsla(0, 0%, 0%, 0.35);
  --hero-background: linear-gradient(hsl(353, 0%, 8.21%), hsl(199, 0%, 4.62%) 98.32%);
  --hero-slate-background: linear-gradient(90deg, hsl(240, 3%, 3%), hsl(240, 3%, 8%) 100%);

  --h2-color: hsla(0, 0%, 35%, 1.0);
  --heading-color: hsla(0, 0%, 100%, 0.90);
  --heading-gradient-background: linear-gradient(360deg, hsl(0, 0%, 85%), hsl(0, 0%, 67%));
  --brand-gradient: linear-gradient(12deg, hsl(245.11, 100%, 75.9%) 10.08%, hsl(258.51, 100%, 79.23%) 44.96%, hsl(262.34, 100%, 75.64%) 67.23%);
  --gradient3: linear-gradient(12deg, hsl(254.68, 70.31%, 27.7%) 10%, hsl(258.51, 68.83%, 29.77%) 44.54%, hsl(256, 79.72%, 35.38%) 68.07%);
  --gradient4: black;
  --gradient5: linear-gradient(to bottom right,hsla(245, 70%, 39%, 1.000),#a783f7,hsla(245, 70%, 39%, 1.000));
  --gradient6: linear-gradient(hsla(268.09, 0%, 0%, 0), rgba(52, 0, 167, 0.16) 39.92%, rgba(0, 0, 0, 0) 92.02%),radial-gradient(circle farthest-corner at 50% 50%, rgba(64, 0, 208, 0.18), rgba(74, 0, 140, 0) 37.39%);
  --gradient7: linear-gradient(360deg, hsla(250.85, 100%, 32.31%, 0), transparent 82.77%),radial-gradient(circle farthest-corner at 50% 50%, rgba(69, 0, 139, 0.12) 53.36%, rgba(92, 2, 194, 0) 78.57%),linear-gradient(to top, rgba(56, 7, 106, 0.01), rgba(38, 4, 88, 0.27) 24.37%, rgba(21, 0, 29, 0) 74.37%);
  --gradient8: linear-gradient(hsla(234, 90%, 21%, 0), hsla(285.32, 100%, 41.54%, 0.39)), radial-gradient(circle at 10% 20%, rgba(92, 0, 173, 0.54), rgba(0, 0, 0, 0.36) 59.66%);
  --gradient9: linear-gradient(170deg,hsla(234,90%,21%,0),hsla(281.49,100%,41.54%,0.09)),radial-gradient(circle at 100% 100%, rgba(105, 55, 203, 0.27), rgba(18, 2, 60, 0.24));
  --gradient10: linear-gradient(hsla(234, 90%, 21%, 0), hsla(285.32, 100%, 41.54%, 0.39)), radial-gradient(circle at 10% 20%, rgba(92, 0, 173, 0.54), rgba(0, 0, 0, 0.36) 59.66%);
  --gradient11: linear-gradient(196deg, hsla(266, 100%, 50%, 0.37), hsla(234, 90%, 21%, 0));
  --gradient12: linear-gradient(209deg, rgba(55, 0, 64, 0), rgba(75, 0, 173, 0.43));

  --cta-background-color: hsla(249.95, 0%, 0%, 0.13);
  --cta-button-background: linear-gradient(12deg, hsl(235.53, 49.43%, 48.73%) 10%, hsl(244, 63.81%, 57.5%) 44.96%, hsl(256, 87.28%, 55.65%) 68.07%);
  --cta-background: linear-gradient(180deg, hsl(0, 0%, 3%), hsl(0, 0%, 7%) 98.32%);
  --cta-border-color: hsla(220, 10%, 18%, 1.0);
  --cta-color: hsla(0, 0%, 92%, 1.0);
  --cta-gradient: linear-gradient(92deg, hsl(240, 3%, 19%) 10%, hsl(240, 3%, 13%));

  --app-section-background: linear-gradient(to bottom, hsla(256.6, 98.98%, 15.98%, 0.6), hsla(245.11, 100%, 17.44%, 0) 98.32%);

  --code-background-color: hsla(0, 0%, 18%, 1.0);

  --contrast-background-color: hsla(0, 0%, 15%, 1.0);
  --contrast-color: hsla(0, 0%, 50%, 1.0);

  --grid-item-background-color: hsla(0, 0%, 11.5%, 1.0);
  --grid-item-color: hsla(0, 0%, 70%, 1.0);

  --checkbox-background-color: hsl(0, 0%, 42%);
  --checkbox-background-color-hover: hsl(224, 100%, 52%);
  --checkbox-background-color-active: hsl(224, 100%, 62%);
  --checkbox-tick-color: hsl(244, 62%, 22%);
  --checkbox-tick-color-active: hsl(244, 62%, 22%);

  --link-color: hsla(0, 0%, 75%, 1.0);
  --link-color-hover: hsla(0, 0%, 94%, 1.0);
  --link-color-active: hsla(0, 0%, 99%, 1.0);

  --modal-background-color: hsla(229.43, 21.95%, 13.94%, 1);

  --em-background-color: hsla(67.15, 90.48%, 47.51%, 1);
  --em-color: hsl(54, 97%, 6%);

  --highlight-background-color: hsla(0, 0%, 10%, 0.9);
  --highlight-border-color: hsla(0, 0%, 12%, 1.0);

  --selection-color: hsla(227, 42%, 32%, 0.3);

  --tasks-background-color: hsl(229.29, 2.04%, 2%);
  --tasks-item-background-color: hsla(0, 0%, 13%, 1.0);
  --float-background-color: hsla(0, 0%, 9%, 1.0);
  --float-border-color: hsla(0, 0%, 15%, 1.0);
  --sidebar-background-color: hsl(229.29, 2.04%, 2%);
  --commander-background-color: hsla(229, 8%, 0%, 1.0);
  --commander-status-background-color: hsla(229, 8%, 3%, 0.7);
  --commander-border-color: hsla(0, 0%, 7%, 1.0);
  --toolbar-background-color: hsla(0, 0%, 4%, 0.92);
  --background-color: hsla(229, 2%, 4%, 1.0);
  --mute-background-color: hsl(210.78, 12.68%, 2.77%);
  --overlay-background-color: hsla(0, 0%, 5%, 0.95);
  --dropdown-background-color: hsla(229, 8%, 10%, 0.90);
  --dropdown-border-color: hsla(0, 0%, 13%, 1.0);
  --tippy-background-color: hsla(229, 8%, 10%, 1.0);

  --tab-background-color: hsla(229.29, 2.04%, 2%, 0.88);
  --tab-background-color-hover: hsla(229.29, 2.04%, 5%, 0.88);
  --tab-background-color-active: hsla(229.29, 2.04%, 9%, 0.88);

  --tab-border-color: hsla(0, 0%, 7%, 1.0);

  --tab-color: hsla(0, 0%, 75%, 1.0);
  --tab-color-hover: hsla(0, 0%, 94%, 1.0);
  --tab-color-active: hsla(0, 0%, 99%, 1.0);

  --translucent-background-color: hsla(0, 0%, 0%, 0.2);
  --translucent-border-color: hsla(0, 0%, 100%, 0.2);
  --bright-border-color: hsla(255.54, 100%, 30.75%, 0.6);
  --pale-border-color: hsla(255.54, 100%, 30.75%, 0.12);
  --border-color: hsla(0, 0%, 13%, 0.6);

  --pale-box-shadow: 0 0 20px -5px hsla(272.33, 100%, 35.25%, 0.32);

  --input-color: hsla(0, 0%, 98%, 1.0);
  --input-label-color: hsla(0, 0%, 70%, 1.0);
  --input-border-color: hsla(213, 11%, 16%, 1.0);
  --input-border-color-focus: hsla(249.95, 100%, 56%, 1);
  --input-background-color: hsl(229.29, 2.04%, 2%);
  --input-background-color-focus: hsl(229.29, 2.04%, 2%);
  --input-placeholder-color: #aaa;
  --input-color-error: hsl(354, 66%, 52%);
  --input-border-color-error: hsl(354, 66%, 52%);

  --search-background-color: hsl(240, 5%, 4%);
  --search-background-color-hover: hsl(240, 5%, 15%);
  --search-border-color: hsl(240, 3%, 10%);
  --search-border-color-hover: hsl(240, 3%, 20%);

  --sidebar-link-color: hsla(0, 0%, 75%, 1.0);
  --sidebar-link-color-hover: hsla(0, 0%, 94%, 1.0);
  --sidebar-link-color-active: hsla(0, 0%, 99%, 1.0);
  --sidebar-link-background-color: hsla(0, 0%, 0%, 0);
  --sidebar-link-background-color-active: hsla(0, 0%, 12%, 1.0);

  --commander-link-color: hsla(0, 0%, 75%, 1.0);
  --commander-link-color-hover: hsla(0, 0%, 80%, 1.0);
  --commander-link-color-active: hsla(0, 0%, 85%, 1.0);
  --commander-link-background-color: hsla(0, 0%, 0%, 0);
  --commander-link-background-color-active: hsla(0, 0%, 12%, 1.0);

  --footer-link-color: hsla(0, 0%, 75%, 1.0);
  --footer-link-color-hover: hsla(0, 0%, 94%, 1.0);
  --footer-link-color-active: hsla(0, 0%, 99%, 1.0);

  --menu-cta-background: linear-gradient(12deg, hsl(235.53, 49.43%, 48.73%) 10%, hsl(244, 63.81%, 57.5%) 44.96%, hsl(256, 87.28%, 55.65%) 68.07%);
  --menu-border-color: hsla(0, 0%, 9%, 1.0);
  --menu-link-color: hsla(0, 0%, 72%, 1.0);
  --menu-link-color-hover: hsla(0, 0%, 90%, 1.0);
  --menu-link-color-active: hsla(0, 0%, 90%, 1.0);
  --menu-link-background-color: hsla(0, 0%, 12%, 1.0);
  --menu-link-background-color-active: hsla(0, 0%, 12%, 1.0);

  --offering-item-background-color: hsl(229.29, 2.04%, 2%);
  --offering-item-color: hsla(0, 0%, 70%, 1.0);
  --offering-item-border-color: hsla(0, 0%, 12%, 1.0);
  --offering-item-border-color-active: hsla(224, 100%, 56%, 1.0);

  --payment-background-color: hsla(0, 0%, 8%, 0.95);
  --invoice-page-background-color: hsla(0, 0%, 6%, 1.0);
  --invoice-background-color: hsla(0, 0%, 13%, 1.0);

  --secondary-menu-border-color: hsla(0, 0%, 7%, 1.0);
  --secondary-menu-link-color: hsla(0, 0%, 72%, 1.0);
  --secondary-menu-link-color-active: hsla(0, 0%, 90%, 1.0);
  --secondary-menu-link-background-color: hsla(0, 0%, 0%, 0);
  --secondary-menu-link-background-color-active: hsla(0, 0%, 12%, 1.0);

  --list-link-color: hsla(0, 0%, 85%, 1.0);
  --list-link-color-active: hsla(0, 0%, 94%, 1.0);
  --list-link-background-color: hsla(0, 0%, 0%, 0);
  --list-link-background-color-active: hsla(0, 0%, 12%, 1.0);

  --list-background-color: hsla(0, 0%, 4%, 0.95);
  --list-border-color: hsla(0, 0%, 14%, 0.8);
  --list-header-background-color: hsla(0, 0%, 7%, 0.9);

  --banner-background-color: hsla(229.29, 2.04%, 7%, 0.88);
  --banner-border-color: hsla(0, 0%, 12%, 1.0);

  --notification-background-color: hsla(229.29, 2.04%, 9%, 0.88);
  --notification-color: hsla(0, 0%, 90%, 1.0);

  --notice-color: hsl(0, 0%, 63%);
  --notice-background-color: hsl(0, 0%, 13%);
  --notice-border-color: hsla(0, 0%, 17%, 1.0);

  --notice-warning-color: hsl(53, 80%, 44%);
  --notice-warning-background-color: hsl(53deg 88% 10%);
  --notice-warning-border-color: hsl(53deg 82% 44% / 18%);

  --notice-info-color: hsl(191, 80%, 44%);
  --notice-info-background-color: hsl(191, 77%, 8%);
  --notice-info-border-color: hsl(191deg 78% 19%);

  --info-background-color: hsla(253.68, 24.22%, 27.77%, 0.24);
  --info-background-color2: hsla(253.68, 24.22%, 27.77%, 0.24);
  --info-background-color3: hsla(253.68, 97.04%, 2.54%, 0.45);
  --info-border-color: hsla(255.54, 100%, 10.75%, 0.3);

  --info-block-background-color: linear-gradient(hsl(225, 4%, 24.65%), hsla(225, 0%, 17.95%, 0.82));
  --info-block-color: hsla(0, 0%, 95%, 1.0);
  --info-block-box-shadow: 0px 13px 40px hsla(0, 0%, 0%, 0.3), 0px 4px 4px hsla(0, 0%, 0%, 0.2);

  --mark-color: hsla(229, 8%, 3%, 1.0);
  --mark-background-color: hsl(50, 86.01%, 57.11%);

  --gradient-one: linear-gradient(92deg, hsl(240, 3%, 6%), hsl(240, 3%, 18%, 0));
  --gradient-two: linear-gradient(144deg, hsl(240, 3%, 4%) 10%, hsl(240, 3%, 18%, 0));

  --screaming-color: hsl(73, 100%, 50%);

  --dot-background: linear-gradient(90deg, hsl(0, 0%, 3%) 15px,transparent 1%) 50%, linear-gradient(hsl(0, 0%, 3%) 15px, transparent 1%) 50%, hsla(0, 0%, 100%, 0.13);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  flex: 1;
  display: flex;
  min-height: 100%;
}

body {
  background: var(--background);
  color: var(--text);
}

textarea,
input[type=text],
input[type=tel],
input[type=password],
input[type=email],
input[type=number] {
  display: block;
  font-size: 16px;
  background: transparent;
  width: 100%;
  box-shadow: none;
  border: 0;
  margin: 0;
  outline: none;
  color: inherit;
  padding: 0.5rem 0.7rem;
  background: var(--background_a5);
  border: 1px solid var(--background_alt);
  border-radius: 5px;
  &:hover {
    border-color: var(--sidebar-text);
  }
  &:focus {
    border-color: var(--sidebar-text);
  }
}

textarea {
  resize: none;
  line-height: 1.6;
}

/* Input */
.input-wrapper {
  &.transparent {
    margin-bottom: 0.4rem;
    input {
      background: none;
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid hsla(0, 0%, 50%, 0.3);
      padding: 0.5rem 0.5rem;
      margin: 0;
    }
  }

  &.h1 {
    input {
      font-size: 2.0rem;
      font-weight: 600;
    }
  }

  &.h2 {
    input {
      font-size: 1.2rem;
      font-weight: 500;
      opacity: 0.6;
    }
  }
}

.input-wrapper label,
.input-label {
  margin: 0 0 6px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.6;
  cursor: default;
}

input[type=text],
input[type=password],
input[type=email] {
  padding: 0.5rem 0.7rem;
}

fieldset {
  margin: 1.0rem 0;
  padding: 0;
  border: 0;
  label {
    display: block;
    margin: 0.6rem 0 0.6rem;
    font-size: 14px;
    color: var(--text);
  }
  &.fieldset-hr {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

form.standard {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 390px;
}

.form-block {
  padding: 0.8rem 1.2rem 0.8rem;
  h3 {
    margin: 0 0 0.8rem;
    padding: 0;
  }
  fieldset {
    margin: 0.3rem 0 0.8rem;
  }
  &--slim {
    margin: 0;
    padding: 0.6rem 0.6rem;
  }
  &--with-bg {
    background: var(--background_a3);
  }
  &.with-danger-zone {
    margin: 1.0rem;
  }
}

form.standard button {
  margin-top: 12px;
  font-size: 15px;
  padding: 6px 10px 6px 10px;
  background: blue;
  color: hsla(0, 0%, 98%, 0.8);
  border-radius: 5px;
  border: 1px solid transparent;
}


main.main {
  flex: 1;
  display: flex;
  &.with-nav {
    padding-left: var(--menu-width);
  }
}

.button-group {
  display: flex;
  align-items: center;
  button,
  a {
    background: var(--ui-button-background-color);
    border: 1px solid var(--border-color);
    color: var(--menu-link-color);

    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.6rem;
    padding: 0 0.6rem;
    margin: 0;
    font-size: 0.875rem;
    border-radius: 0;

    svg {
      width: 1.0rem;
    }

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      position: relative;
      z-index: 1;
      background: var(--ui-button-background-color-hover);
      border-color: var(--logo-color);
      color: var(--menu-link-color-hover);
    }

    &.is-active {
      color: var(--menu-link-color-active);
      background: var(--ui-button-background-color-hover);
    }

    div {
      font-size: 1.2rem;
      margin-bottom: 0.3rem;
      &.sansserif {
      }
      &.serif {
        font-family: serif;
      }
      &.mono {
        font-family: monospace;
      }
      &.slab {
        font-family: Futura;
      }
    }

    & + a, button {
      margin-left: -1px;
    }

    &.with-icon {
      padding: 0.6rem 0.6rem;
      svg {
        margin-bottom: 0.2rem;
        width: 1.2rem;
      }
    }
  }
}

.app-build-info {
  font-size: 12px;
  padding: 1.5rem;
  opacity: 0.4;
}
