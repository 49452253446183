.payout-inputs-grid {
  width: 100%;
  display: grid;
  grid-row-gap: 0.6rem;
  grid-column-gap: 1.2rem;
  grid-template-columns: 1fr 1fr;
}

.payout-body {
  display: block;
  padding: 1.2rem 1.0rem;
  width: 100%;
}

.payout-body-item {
  margin-bottom: 1.4rem;
}

.payout-body-head {
  display: flex;
  align-items: center;
  margin-top: 1.2rem;
  .select-button-group {
    margin-left: 1.0rem;
  }
  &:first-of-type {
    margin-top: 0;
  }
  h4 {
    font-size: 0.9325rem;
    font-weight: 500;
  }
}

.payout-method-caption {
  font-size: 0.875rem;
  width: 100%;
  line-height: 1.5;
  opacity: 0.6;
}

// limited overlay
.payout-limited-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsla(0, 0%, 0%, 0.1);
  transition: all 340ms ease;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.payout-limited {
  width: 100%;
  max-width: 400px;
  padding: 1.0rem;
  margin: 0 auto;
  background: var(--sidebar-background);
  border: 1px solid hsla(0, 0%, 0%, 0.1);
  border-radius: 6px;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

  h3 {
    margin: 0 0 0.5rem;
  }

  p {
    opacity: 0.7;
    margin: 0;
    line-height: 1.5;
  }
}
