.usage-list {
  flex: 1;
  padding: 1.0rem;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 2px dotted var(--sidebar-background);
    margin-bottom: 1.0rem;
  }

  h4 {
    margin: 0;
    font-weight: 500;
    small {
      margin-left: 0.5rem;
      opacity: 0.6;
    }
  }

  .limit-text {
    opacity: 0.6;
  }
}
