.loadingScreen {
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, var(--background), var(--sidebar-background));
  padding-bottom: 3.0rem;

  transition: all 450ms ease;
  transition-delay: 450ms;

  &:global {
    &.is-hidden {
      opacity: 0;
      visibility: hidden;
      transform: scale(1.07);
    }
  }

  img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    margin-bottom: 1.5rem;
  }
}
