.pitchWrapper {
  width: 100%;
  max-width: 480px;
  padding: 0;
  background: var(--sidebar-background);
  border: 1px solid hsla(0, 0%, 0%, 0.1);
  border-radius: 6px;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.pitchHeader {
  padding: 1.5rem 2.4rem 0;
  text-align: center;
  img {
    display: block;
    max-width: 3.2rem;
    margin: 0 auto 2.0rem;
    border-radius: 5px;
  }
  h1 {
    font-size: 1.4rem;
    font-weight: 500;
    padding: 0;
    line-height: 1.2;
  }
  p {
    font-size: 1.0rem;
    padding: 0;
    opacity: 0.7;
    line-height: 1.5;
  }
}

.pitchBody {
  padding: 1.0rem 2.4rem 1.5rem;
}

.pitchActions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 2.0rem 1.0rem;
  margin: 0;
  a {
    margin-top: 9px;
    font-size: 16px;

    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;

    margin-left: 0.4rem;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 0.5rem 0.9rem;
    border-radius: 5px;
    text-align: center;
    justify-content: center;

    &.cta {
      background: var(--sidebar-text);
      color: var(--background);
    }

    &.opaque {
      opacity: 0.7;
      &:hover {
        opacity: 0.9;
      }
    }

    svg {
      max-width: 1.5rem;
      max-height: 1.5rem;
      width: 100%;
      height: 100%;
      margin-right: 0.5rem;
      fill: currentColor;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.pitchFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.0rem 1.0rem;
  margin-bottom: 1.0rem;
  a {
    margin-left: 0.6rem;
    color: var(--sidebar-text);
    padding: 3px 1px;
    border-bottom: 1px solid currentColor;
  }
}
