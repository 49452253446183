.userSwitcher {
  padding: 0;
  margin: 0;
  display: flex;
  margin-left: 0.6rem;
  margin-right: 1.0rem;
  align-items: center;
  -webkit-app-region: no-drag;
  transition: all 200ms ease;

  z-index: 3;

  transform: scale(0.96);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &:global {
    &.isActive {
      transform: scale(1);
      pointer-events: all;
      opacity: 1;
      visibility: visible;
    }
  }
}

.styledLink {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  margin: 0;
  padding: 0.4rem 1.0rem;

  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  text-align: left;
  white-space: normal;

  border: 1px solid hsla(0, 0%, 0%, 0);
  color: var(--text);

  &:hover {
    color: var(--text_a_10);

    background: hsla(0, 0%, 50%, 0.06);
  }

  &:global {
    &.active {
      color: var(--text_a_10);
      background: hsla(0, 0%, 50%, 0.1);
    }

    &.small {
      font-size: 0.8125rem;
      font-weight: 400;
      svg {
        margin-right: 0.5rem;
      }
    }

    &.nested {
      padding-left: 1.5rem;
    }

    &.header {
      padding: 0.4rem 0.9rem;
      font-size: 0.9325rem;
      svg {
        width: 1.0rem;
        margin-right: 0.2rem;
      }
    }

    &.product {
      font-size: 0.875rem;
    }

    &.with-icon {
      min-height: 2.5rem;
    }

    &.bigger-icon svg {
      left: 0.9rem;
      width: 1.1rem;
    }

    &.language {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  svg {
    position: absolute;
    padding: 0;
    left: 1.0rem;
    margin: 0 0.4rem 0 0;
    width: 0.875rem;
    margin-right: 0.4rem;
  }

  img {
    padding: 0;
    margin: 0 0.6rem 0 0;
    width: 1.4rem;
    border-radius: 4px;
  }
}

.styledText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  margin: 0;
  padding: 0.4rem 1.0rem;

  font-size: 0.8125rem;
  font-weight: 400;
  text-decoration: none;
  text-align: left;
  white-space: normal;
  word-break: break-all;

  border: 1px solid hsla(0, 0%, 0%, 0);
  color: var(--text);

  &:global {
    &.small {
      font-size: 0.75rem;
      font-weight: 400;
      svg {
        margin-right: 0.5rem;
      }
    }

    &.nested {
      padding-left: 1.5rem;
    }

    &.header {
      padding: 0.4rem 1.0rem 0;
      font-size: 0.875rem;
      svg {
        width: 1.0rem;
        margin-right: 0.2rem;
      }
    }

    &.product {
      font-size: 0.8125rem;
    }

    &.with-icon {
      padding-left: 2.2rem;
    }

    &.bigger-icon svg {
      left: 0.9rem;
      width: 1.1rem;
    }
  }

  svg {
    position: absolute;
    padding: 0;
    left: 1.0rem;
    margin: 0 0.4rem 0 0;
    width: 0.875rem;
    margin-right: 0.4rem;
  }
}

.userSwitcherButton {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  background: var(--ui-button-background-color);
  border: 1px solid var(--b_med);
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  outline: none;

  &:hover {
    border-color: var(--b_high);
  }

  img {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  svg {
    margin: 0;
    width: 1.1rem;
    height: 1.1rem;
    align-self: flex-end;
    justify-self: center;
  }
}

.userSwitcherContent {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  margin-top: 0.6rem;
  right: -0.6rem;
  z-index: 2;
  width: 195px;
  padding: 0.3rem 0;

  color: var(--text);
  background: var(--background_a5);
  border-radius: 4px;
  border: 1px solid var(--background_a3);
  border-radius: 4px;
  box-shadow: 0 40px 28px hsla(240, 0%, 0%, 0.03),
              0 12px 12px hsla(240, 0%, 0%, 0.015),
              0 5px 10px hsla(0, 0%, 0%, 0.01);


  font-size: 0.875rem;
  transition: all 100ms ease;

  transform: translateY(1rem);
  transform-origin: 0% 0%;

  -webkit-backdrop-filter: saturate(150%) blur(8px);

  opacity: 0;
  visibility: hidden;

  &:global {
    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  @media (min-width: 480px) {
    flex-direction: row;
  }
}

.userSwitcherSection {
  padding: 0.4rem 0;

  @media (max-width: 479px) {
    border-right: 1px solid var(--border-color);
    &:last-of-type {
      border-right: 0;
    }
  }

  @media (min-width: 480px) {
    border-bottom: 1px solid var(--border-color);
    &:last-of-type {
      border-bottom: 0;
    }
  }
}


.userSwitcherInfo {
  margin-bottom: 0.2rem;
  padding: 0.5rem 1.0rem;
  font-size: 0.8125rem;
}

.userSwitcherBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--border-color);
  &:last-of-type {
    border-right: 0;
  }
}

.onlineIndicator {
  position: absolute;
  bottom: -1px;
  right: -1px;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  &:global {
    &.ONLINE {
      background: green;
    }
    &.OFFLINE {
      background: grey;
    }
    &.CONNECTING {
      background: yellow;
    }
    &.ERROR {
      background: darkred;
    }
  }
}

.sideFooterTheme {
  display: flex;
  align-items: center;
  margin: 0.4rem 0 0;
  padding: 0 0.8rem;
}

.sideFooterLogo {
  display: flex;
  align-items: center;
  margin: 0.6rem 0;
  padding: 0 1.0rem;
  opacity: 0.5;
  font-size: 0.75rem;
  svg {
    margin-right: 0.5rem;
    width: 1.0rem;
  }
}

.separator {
  margin: 0.2rem 0.2rem;
  border-top: 1px solid var(--background);
}
