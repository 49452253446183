.welcomeScreenWrapper {
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem 4rem;
  background: var(--background_a5);
  color: var(--text);
  &.is-wide .welcomeScreenBlock {
    width: 100%;
    max-width: 720px;
  }

  &.is-minimal {
    background: none;
  }
}

.mainHeader {
  padding: 0 0 1rem;
}

.welcomeScreenBlock {
  width: 100%;
  max-width: 420px;
  background: var(--background);
  border-radius: 5px;
  margin: 0 0 0.5rem;
  transition: all 220ms ease;
  p {
    line-height: 1.6;
    font-size: 1.0rem;
  }
}

.welcomeScreenBlockHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.2rem;
  border-bottom: 1px solid hsla(0, 0%, 50%, 0.1);
  h3 {
    margin: 0;
    font-size: 1.0rem;
    font-weight: 500;
  }
}

.welcomeScreenBlockBody {
  padding: 0.5rem 1.0rem;
  h3 {
    margin-left: 0.5rem;
    margin-bottom: 1.8rem;
  }
  video {
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    border: 1px solid var(--background_a3);
  }
}

.title {
  margin: 0 0 1.0rem;
  line-height: 1.25;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0;
}

.selectWorkspaceList {
}

.selectWorkspaceListItem {
  display: flex;
  align-items: center;
  margin: 0.6rem 0 0.8rem;
  padding: 0.4rem 0.5rem;
  border-radius: 5px;
  &:hover {
    background: var(--background_a5);
  }
  &:last-of-type {
    margin-bottom: 0.6rem;
  }
}

.workspaceActions {
  padding: 0.5rem 0;
  margin: 0 0 0.5rem;
  a {
    color: var(--accent);
  }
  button + button {
    margin-left: 0.5rem;
  }
}

.grid-selection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 0.6rem;
  grid-column-gap: 0.6rem;
  button {
    padding: 0.7rem 0.9rem;
    background: var(--background_a3);
    border-radius: 5px;
    font-size: 0.9235rem;
    &.is-active {
      background: var(--sidebar-text);
      color: var(--sidebar-background);
    }
  }
}

.welcomeScreenBlockNavigation {
  margin-top: 1.2rem;
  border-top: 1px solid var(--background_a3);
  padding: 1.0rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-answer-input {
  margin: 1.0rem 0;
  input {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
}

.points-grid {
  display: grid;
  margin: 1.2rem 0.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 1.5rem;
  grid-column-gap: 2.0rem;
  & > div {
    flex-direction: column;
  }
  h3 {
    margin: 0;
    font-size: 1.0rem;
    font-weight: 600;
  }
  p {
    margin: 0.4rem 0;
    opacity: 0.7;
  }
}

.points-grid-icon {
  margin-bottom: 0.5rem;
  svg {
    width: 1.8rem;
    fill: currentColor;
  }
}

.canvas-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: hsla(0, 0%, 0%, 0.3);
  transition: all 340ms ease;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);

  canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    right: 0;
    bottom: 0;
    opacity: 0.5;
  }
}

.welcomeScreenProfile {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  padding: 1.2rem 0.4rem;
  width: 100%;
  margin: 0 auto;
  max-width: 460px;
  & > div:first-child {
    margin-bottom: 2.0rem;
  }

  .select-image {
    width: 180px;
  }
}

.invite-caption {
}

.invite-field-caption {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  background: var(--background_a5);
  margin: 0.25rem 0;
  padding: 0.1rem 0.3rem;
  border-radius: 5px;
  img {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.4rem;
    border-radius: 5px;
  }
}
