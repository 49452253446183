.billingSetup {
}

.addCardWrapper {
  max-width: 400px;
}

.cardList {
  margin: 0;
}

.placeholder {
  opacity: 0.8;
}

.addCardWrapperHead {
  display: flex;
  align-items: center;
  h4 {
    margin-left: 1.0rem;
  }
}

.addCardWrapperBody {
}

.cardList {
}

.cardListHeader {
  display: flex;
  align-items: center;
  svg {
    width: 1.4rem;
    margin-left: 4px;
  }
}
