.trialActivationOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: hsla(0, 0%, 0%, 0.5);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  z-index: 8;
}

.trialActivationWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;

  max-width: 720px;

  margin: 1rem;
  cursor: default;
  overflow-y: auto;

  background: var(--background);
  border: 1px solid var(--sidebar-background);
  border-radius: 4px;
  box-shadow: 0 40px 28px hsla(240, 0%, 0%, 0.03),
              0 12px 12px hsla(240, 0%, 0%, 0.015),
              0 5px 10px hsla(0, 0%, 0%, 0.01);

  @media (min-width: 640px) {
    margin: auto auto;
    max-height: 300px;
  }
}

.trialActivationContent {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--background);
}

.trialActivationHead {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.0rem 1.5rem;
}

.trialActivationProgress {
  padding: 0.4rem 1.0rem;
  button {
    margin: 0 0.3rem;
    padding: 0.3rem 0.4rem;
    border: 1px solid var(--background);
    border-radius: 5px;
    font-size: 14px;
    transition: all 100ms ease;
    &:global {
      &.is-active {
        color: var(--sidebar-text);
        border-color: currentColor;
      }
    }
    @media (min-width: 720px) {
      padding: 0.4rem 0.6rem;
      margin: 0 0.4rem;
      font-size: 15px;
    }
  }
}

.trialActivationBody {
  flex: 1;
  display: flex;
  border-radius: 5px;
  margin: 0 1.0rem;
  padding: 1.2rem 1.5rem;
  line-height: 1.6;
  overflow-y: auto;
  a {
    padding: 3px 0;
    color: var(--sidebar-text);
    border-bottom: 1px solid;
  }
}

.trialActivationFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2.4rem;
  font-size: 14px;
  opacity: 0.6;
}

.notAMakerYet {
  margin: 3.5rem 0 0;
  font-size: 0.9325rem;
}
