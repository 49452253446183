.topbarMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  padding: 1.5rem 1.5rem;

  & > div {
    display: flex;
    align-items: center;
  }

  :global {
    .logo {
      width: 2.0rem;
      border-radius: 5px;
      margin-right: 1.5rem;
    }
    .logo-link {
      margin-right: -1.5rem;
    }
  }

  a {
    opacity: 0.8;
    &:hover {
      opacity: 1.0;
    }
    & + a {
      margin-left: 1.5rem;
    }
  }
}
