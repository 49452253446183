.menu {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;

  padding-top: 3.5rem;
  width: var(--secondary-menu-width);
  background: var(--sidebar-background);
  border-right: 1px solid var(--background_a5);

  transition: var(--sidebar-transition);

  transform: translateX(-100px);
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;

  &:global {
    &.is-active {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }

  @media (min-height: 800px) {
    padding-top: 9vh;
  }
}

.menuLink {
  position: relative;
  display: block;

  margin: 0.25rem 0.6rem;
  padding: 0.3rem 0.9rem;

  font-size: 0.875rem;
  font-weight: 400;

  text-decoration: none;
  color: var(--text);

  transition: all 200ms ease;
  line-height: 1.5;

  text-overflow: ellipsis;

  border-radius: 4px;

  img {
    height: 5.0rem;
    width: 5.0rem;
    margin-left: -0.2rem;
    margin-right: 0.3rem;
  }

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 1.0rem;
    left: 0.8rem;
    opacity: 0.75;
    color: currentColor;
  }

  &.with-icon {
    padding-left: 2.4rem;
  }

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }

  &:global {
    &.active {
      color: var(--sidebar-background);
      background: var(--sidebar-text);
    }
  }
}

.menuLinkCaption {
  margin-top: 0.15rem;
  font-size: 0.8125rem;
  opacity: 0.8;
}

.menuLabel {
  font-size: 0.9375em;
  opacity: 0.75;
}

.menuBlock {
  display: flex;
  flex-direction: column;
  margin: 0.4rem 0;
}

.menuBlockTitle {
  margin: 0.25rem 0.6rem;
  padding: 0.45rem 0.9rem;
  font-size: 0.6875rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.8;

  &.struct-title {
    margin: 1.0rem 0.6rem;
    font-size: 1.0rem;
    opacity: 1.0;
    text-transform: none;
    letter-spacing: 0;
  }

  .struct-icon {
    display: block;
    font-size: 1.6rem;
    margin-bottom: 1.0rem;
  }

  .struct-type {
    margin-top: 0.5rem;
    display: inline-block;
    font-size: 11px;
    padding: 2px 4px;
    border-radius: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 1px solid var(--border-color);
    opacity: 0.6;
  }
}

.menuBlockTitleLink {
  display: inline-flex;
  margin-top: -1px;
  margin-left: 0.5rem;
  vertical-align: middle;
  svg {
    width: 1rem;
  }
}

.menuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.4rem;

  input {
    max-width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    outline: none;
    font-size: 0.9325rem;
    font-weight: 600;
    color: var(--color);
  }
}

.menuHeaderTitle {
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
}

.menuHeaderAddButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-weight: 400;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.1rem;
  padding: 0;
  color: var(--color);
  cursor: pointer;
  &:hover {
    border-color: hsla(228, 64%, 50%, 1.0);
  }
}
