.section {
}

.sectionBody {
}

.userRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0;
  margin: 0 0;
  border-bottom: 1px solid var(--border-color);
}

.userInfo {
  display: flex;
  align-items: center;
  img {
    width: 2.0rem;
    height: 2.0rem;
    border-radius: 50%;
    margin-right: 0.8rem;
  }
}

.userIDs {
  strong {
    margin: 0;
    font-weight: 500;
    font-size: 0.9375rem;
  }
  p {
    margin: 0.3rem 0 0;
    opacity: 0.7;
    font-size: 0.875rem;
  }
}

.userAccess {
  display: flex;
  align-items: center;
}

.userStatus {
  margin: 0 0.4rem;
  font-size: 0.875rem;
  opacity: 0.7;
}

.userRole {
  margin: 0 0.4rem;
  font-size: 0.875rem;
}

.inviteBaseWrapper {
  padding: 0;
}

.inviteHeader {
}

.inviteForm {
  textarea {
    width: 100%;
    resize: none;
    padding: 0.4rem 0.6rem;
    background: transparent;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    transition: all 100ms ease;
    color: var(--sidebar-text);
    &:focus {
      border-color: var(--sidebar-text);
      outline: none;
    }
  }
  button {
    font-size: 0.9325rem;
    margin: 0.6rem 0;
    padding: 0.4rem 0.6rem;
    background: transparent;
    border-radius: 4px;
    border: 1px solid var(--sidebar-text);
    color: var(--sidebar-text);
    &:hover {
      color: var(--sidebar-background);
      background: var(--sidebar-text);
    }
  }
}

.inviteList {
}

