.workspaceSelectWrapper {
  flex: 1;
  max-width: 192px;
  margin-right: 0.5rem;
  height: 1.6rem;
}

.userDetails {
  padding: 0.5rem 1.0rem;
  font-size: 0.875rem;
  color: var(--text);
}

.selectWrapper {
  width: 100%;
}

.optionList {
  padding: 0.3rem 0;
}

.selection {
  position: relative;
  color: var(--text);
  display: flex;
  align-items: center;
  min-width: 144px;
  margin: 0;
  padding: 0.2rem 1.2rem 0.2rem 1px;
  height: 1.6rem;
  font-size: 0.875rem;
  background: var(--background_a5);
  border-radius: 4px;
  border: 1px solid var(--background_a3);

  :global {
    .workspace-icon {
      border: 1px solid var(--border-color);
    }
  }

  &:hover {
    background: var(--background_a10);
    :global {
      .workspace-icon {
        border-color: var(--logo-color);
      }
    }
  }

  svg {
    position: absolute;
    top: 50%;
    right: 0.2rem;
    width: 0.9325rem;
    z-index: 2;
    margin-top: -0.5em;
    fill: currentColor;
  }
}

.selectionBlock {
}

.separator {
  margin: 0.2rem 0.2rem;
  border-top: 1px solid var(--background_a10);
}

.selectionContent {
  position: absolute;
  left: 1.0rem;
  z-index: 6;
  width: 195px;
  margin-top: 0.6rem;
  padding: 0.3rem 0;

  opacity: 0;
  visibility: hidden;

  color: var(--text);
  background: var(--background_a5);
  border-radius: 4px;
  border: 1px solid var(--background_a3);
  border-radius: 4px;

  box-shadow: 0 40px 28px hsla(240, 0%, 0%, 0.03),
              0 12px 12px hsla(240, 0%, 0%, 0.015),
              0 5px 10px hsla(0, 0%, 0%, 0.01);

  backdrop-filter: saturate(150%) blur(8px);

  transition: all 100ms ease;

  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  transform-origin: 100% 50%;

  &:global {
    &.active {
      opacity: 1;
      visibility: visible;
      transform: scale(1.0);
    }
  }
}

.workspaceList {
  padding: 0.1rem 0;
}

.workspaceListItem {
  padding: 0.1rem 0;
  &:hover {
  }
}

.styledLink {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  margin: 0;
  padding: 0.4rem 1.0rem;

  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  text-align: left;
  white-space: normal;

  border: 1px solid hsla(0, 0%, 0%, 0);
  color: var(--text);

  &:hover {
    color: var(--text_a_10);
    background: hsla(0, 0%, 50%, 0.06);
  }

  &:global {
    &.active {
      color: var(--text_a_10);
      background: hsla(0, 0%, 50%, 0.1);
    }

    &.small {
      font-size: 0.8125rem;
      font-weight: 400;
      svg {
        margin-right: 0.5rem;
      }
    }

    &.withIcon {
      padding-left: 2.2rem;
    }

    &.workspaceListItem {
      font-size: 0.8125rem;
      &.active {
      }
    }

    &.mute {
      font-size: 0.875rem;
      color: var(--text_a_10);
    }

    & > svg {
      position: absolute;
      padding: 0;
      left: 1.0rem;
      margin: 0 0.4rem 0 0;
      width: 0.875rem;
      margin-right: 0.4rem;
    }
  }
}

.workspaceMark { // span
  position: absolute;
  right: 1.0rem;
  color: var(--action);
  svg {
    width: 1rem;
  }
}
