.awareness-wrapper {
  padding: 0.5rem 0.8rem 0.5rem;
}

.awareness-user {
  display: inline-flex;
  align-items: center;
  margin-right: 0.4rem;
  img {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
  }
}
