.new-workspace-form-field {
  margin: 0rem 0 1.0rem;
  label {
    display: block;
    margin: 0.3rem 0 0.8rem;
    padding: 0 0.5rem;
    letter-spacing: 0.2px;
  }
}

.new-workspace-form-wrapper {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 440px;
  padding: 0;
  background: var(--sidebar-background);
  border: 1px solid hsla(0, 0%, 0%, 0.1);
  border-radius: 6px;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.new-workspace-form-header {
  padding: 1.5rem 2.4rem 0;
  img {
    display: block;
    max-width: 3.2rem;
    margin: 0 auto 2.0rem;
    border-radius: 5px;
  }
  h1 {
    font-size: 1.3rem;
    padding: 0 0.5rem;
  }
}

.new-workspace-form-body {
  padding: 0rem 2.4rem 1.0rem;
}

.new-workspace-form-wrapper input {
  width: 100%;
  font-size: 16px;
  padding: 12px 16px 12px 16px;
  background: var(--background_a3);
  border-radius: 5px;
  border: 1px solid var(--background_a3);
  transition: border 100ms ease;

  &:focus {
    outline: none;
    border: 1px solid var(--sidebar-text);
  }
}

.new-workspace-form-wrapper button {
  width: 100%;
  display: block;
  margin-top: 9px;
  font-size: 16px;
  padding: 14px 14px 14px 14px;
  background: var(--sidebar-text);
  color: var(--background);
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;
}

.separator {
  display: block;
  text-align: center;
  padding: 1rem 0;
}

.new-workspace-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: hsla(0, 0%, 0%, 0.5);
  transition: all 340ms ease;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

  opacity: 0;
  visibility: hidden;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }
}

.new-workspace-window {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 7;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

