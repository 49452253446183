.App {
  flex: 1;
  display: flex;
  padding-top: 2.0rem;
  padding-top: 0;
  transition: all 120ms ease;
  & > div {
    flex: 1;
    display: flex;
  }

  &.with-nav {
    padding-top: 0;
    padding-left: 240px;
  }
  &.is-desktop {
    .nav {
      padding-top: 1.0rem;
    }

    .drags-window {
      -webkit-user-select: none;
      -webkit-app-region: drag;
    }
  }
}

.logo-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem 0 0;
  width: 2.0rem;
  height: 2.0rem;
  background: linear-gradient(to right, var(--background), var(--background_a5));
  border: 1px solid var(--background_a5);
  border-radius: 5px;
  svg {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    color: var(--text);
    path {
      fill: currentColor;
    }
  }
}

.iconButton {
  position: relative;
  z-index: 1;
  width: 1.4rem;
  height: 1.4rem;
  color: var(--accent);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border: 1px solid var(--accent);
  border-radius: 5px;
  background: none;
  box-shadow: none;
  cursor: pointer;
  svg {
    position: absolute;
    z-index: 1;
    margin: auto;
    display: block;
    width: 1.0rem;
    height: 1.0rem;
    fill: currentColor;
  }
}

.view-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1rem 14px 1.2rem;
  height: var(--view-header-height);
  justify-content: space-between;
  transition: padding 120ms ease;

  h3 {
    margin: 0;
    font-size: 1.0rem;
    font-weight: 400;
    color: var(--text);
  }

  &.with-toggle-button {
    @media (max-width: 1279px) {
      padding-left: 3.6rem;
    }
  }

  &.with-border {
    border-bottom: 1px solid var(--background_a3);
  }

  &.placed {
    padding-top: 5rem;
  }
}

.view-header-headline {
  display: flex;
  align-items: center;
  padding: 0;
  svg {
    margin-top: 4px;
    margin-right: 6px;
    height: 20px;
    width: 20px;
  }
}

.view-header-button {
  margin: 0;
  border: 0;
  background: none;
  box-shadow: none;
  padding: 6px 6px;
  color: var(--accent);
  border: 1px solid var(--accent);
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &.cancel {
    background: var(--background);
    color: var(--text);
    border: 1px solid var(--background_a5);
    opacity: 0.8;
  }
  &.cta {
    color: var(--accent);
    background: var(--background);
    border: 1px solid var(--accent);
  }
  &[disabled] {
    pointer-events: none;
  }
}

.empty-view-box {
  display: flex;
  flex-direction: column;
  padding: 1.0rem 1.3rem;
  flex: 1;
  line-height: 1.6;
  &--central {
    align-items: center;
    justify-content: center;
  }
  p {
    margin: 0.5rem 0;
    font-size: 15px;
    opacity: 0.7;
  }
  button {
    margin: 0;
    border: 0;
    background: none;
    box-shadow: none;
    padding: 4px 9px;
    color: var(--accent);
    border: 1px solid var(--accent);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}

.fyi-box {
  padding: 0 1.5rem;
  line-height: 1.6;
  p {
    color: var(--text);
  }
}

button[disabled] {
  opacity: 0.6;
  cursor: default;
}

.page-with-preview {
  display: flex;
  flex: 1;
  align-items: stretch;
}

.preview-wrapper {
  flex: 6;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--background_darker);
}

.preview-renderer {
  flex: 1;
  display: flex;

  border: 1px solid var(--background_a3);
  border-radius: 10px;

  overflow: hidden;

  // iframe used in pdf renderer
  iframe {
    width: 100%;
    border: 0;
  }
}

.epub-container {
  display: flex;
  flex: 1;
}

.epub-renderer {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  padding: 3.0rem 1.0rem 1.5rem;
  background: hsla(0, 0%, 0%, 0.3);

  .epub-renderer-prev-button,
  .epub-renderer-next-button {
    position: absolute;
    top: 50%;
    z-index: 5;
    color: var(--accent);
    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  .epub-renderer-prev-button {
    left: 1rem;
  }

  .epub-renderer-next-button {
    right: 1rem;
  }

  .epub-renderer-caption {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: grey;
  }

  .epub-container,
  .epub-view {
  }

  .epub-renderer-wrapper {
    flex: 1;
    margin: auto;
    height: calc(100vh - 160px);
    aspect-ratio: 3 / 4;
  }

  .epub-renderer-ref {
    margin: auto;
    height: calc(100vh - 160px);
    aspect-ratio: 3 / 4;
    border-radius: 9px;
    overflow: hidden;
  }
}

.preview-body {
  flex: 1;
  display: flex;
  margin: 0 1.0rem;
  min-height: 80vh;
  max-height: calc(100vh - var(--view-header-height) - 1.0rem);

  font-family: Inter, -apple-system, sans-serif;

  letter-spacing: 0;
  line-height: 1.7;

  font-weight: 400;
  font-size: 1.0rem;
  font-style: normal;

  transition: background 320ms ease;

  overflow-x: hidden;
  overflow-y: auto;
}

fieldset.icon-input-wrapper {
  .icon-input {
    min-width: 3rem;
    margin-right: 4px;
  }
  .input-group {
    display: flex;
  }
}

.section-block {
  margin: 0.8rem 0 1.4rem;
  padding: 0 0.6rem;
}

.section-block-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.8rem 0.6rem;
  h4 {
    margin: 0;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: 0.6;
    cursor: default;
    color: var(--accent);
  }
}

.section-block-add-button {
  display: inline-flex;
  align-items: center;
  border: 0;
  background: none;
  box-shadow: none;
  padding: 3px 3px;
  color: var(--accent);
  border: 1px solid hsla(0, 0%, 50%, 0.4);
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  svg {
    fill: currentColor;
    width: 1rem;
  }
}

.section-block-body {
  padding: 0.4rem 0;
}

.select-button-group {
  display: inline-flex;
  &.full-width {
    display: flex;
  }
  &.mini {
    button {
      padding: 3px 7px;
    }
  }
  button {
    flex: 1;
    border: 0;
    background: none;
    box-shadow: none;
    padding: 6px 10px;
    border-radius: 0;
    color: var(--sidebar-text);
    border: 1px solid var(--sidebar-text);
    font-size: 0.875rem;
    white-space: nowrap;
    cursor: pointer;
    &:first-of-type {
      border-radius: 5px 0 0 5px;
    }
    &:last-of-type {
      border-radius: 0 5px 5px 0;
    }
    &:hover {
      opacity: 0.8;
    }
    & + button {
      margin-left: -1px;
    }
    &.is-active {
      background: var(--sidebar-text);
      color: var(--sidebar-background);
    }
  }
}

.pdf-renderer {
  position: relative;
}

.epub-renderer-actions,
.pdf-renderer-actions {
  position: absolute;
  top: 0;
  left: 0;
  margin: 1.0rem;
  z-index: 5;
  display: flex;
  align-items: center;
  line-height: 1.2;

  a,
  button {
    border: 0;
    display: inline-flex;
    white-space: nowrap;
    background: none;
    box-shadow: none;
    padding: 4px 9px;
    color: var(--accent);
    background: var(--background);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    & + button,
    & + a {
      margin-left: 1.0rem;
    }
  }
}

.nested-section-view {
  background: var(--background);
}

.nested-scrollable-view {
  max-height: calc(100vh - var(--view-header-height));
  overflow-y: auto;
}

/* Popover
 * ****** */
.popover-wrapper {
  position: relative;
  & + .popover-wrapper {
    margin-left: 1.0rem;
  }
}

.popover-button {
  display: inline-flex;
  color: var(--text);
  font-size: 14px;
  &:hover {
    color: var(--sidebar-text);
  }
  &.is-active {
    color: var(--sidebar-text);
  }
}

.popover-content {
  position: absolute;
  top: 100%;
  margin-top: 0.5rem;
  z-index: 5;
  min-width: 240px;
  padding: 0.2rem 0.5rem;
  background: var(--background);
  border-radius: 6px;
  border: 1px solid var(--background_a5);
  opacity: 0;
  visibility: hidden;
  &.from-right {
    right: 0;
  }
  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  h3 {
    font-size: 1.0rem;
    margin: 0.5rem 0 0.8rem;
  }
}

/* Inclusion
 * ************ */
.inclusion-list {
  padding: 0;
  margin: 0;
  list-style: none;
  label {
    display: block;
    margin: 0.5rem 0;
  }
  ul {
    padding: 0;
    margin: 0 0 0 1.0rem;
    list-style: none;
  }
}

/* Translation
 * ************ */
.translation-list {
  padding: 0;
  margin: 0;
  list-style: none;
  ul {
    padding: 0.4rem 0;
    margin: 0.8rem 0;
    list-style: none;
  }
  li {
    margin: 0.6rem 0;
    font-size: 15px;
  }
}

/* Horizontal Menu
 * ***************** */
.horizontal-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 1rem 1rem 4.0rem;
  background: var(--sidebar-background);
  border-bottom: 1px solid var(--background_a5);
}

.horizontal-menu-block {
  display: flex;
  align-items: flex-end;
}

.horizontal-menu-block-title {
  font-weight: 600;
  font-size: 1.1rem;
  margin-right: 0.8rem;
}

.horizontal-menu-link {
  margin: 0 0.4rem;
  font-size: 15px;
  &.active {
    color: var(--sidebar-text);
  }
}

// plan confirmation
.workspace-plan-step {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.back-button,
.next-button {
  display: inline-flex;
  align-items: center;
  padding: 0;
  z-index: 1;
  svg {
    width: 1.4rem;
  }
}
.back-button {
  svg {
    margin-right: 0.4rem;
  }
}
.next-button {
  svg {
    margin-left: 0.4rem;
  }
}

.workspace-plan-step-head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.0rem;
  height: 3.2rem;
  line-height: 1.2;
  border-bottom: 1px solid var(--background_a3);
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
    margin: 0;
    padding: 0;
    font-size: 1.1rem;
    font-weight: 600;
  }
  & > div {
    display: flex;
    align-items: center;
  }
}

.workspace-plan-step-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.0rem 1.3rem;
  line-height: 1.7;
}

.workspace-plan-step-footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.0rem 1.3rem;
  border-top: 1px solid var(--background_a3);
  .cta-button {
    background: var(--sidebar-text);
    border: 1px solid var(--sidebar-background);
    color: var(--sidebar-background);
    border-radius: 5px;
    font-size: 15px;
    padding: 0.5rem 0.7rem;
    & + .cta-button-caption {
      margin-left: 1.0rem;
    }
  }
  &.single {
    justify-content: flex-start;
  }
}

.add-payment-card-form {
  background: var(--background_a3);
  border-radius: 5px;
  padding: 1.0rem;
}

.flex-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  padding: 0.5rem 0 0.5rem;
  border-bottom: 1px solid var(--background_a3);
  &:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
  }

  h4 {
    margin: 0;
    padding: 0;
  }

  .credit-card-mini-wrapper {
    margin: 0;
    background: none;
  }
}

.cta-button-caption {
  font-size: 14px;
  opacity: 0.7;
  & + button {
    margin-left: 1.0rem;
  }
}

.checkbox-circle-wrapper {
  display: flex;
  align-items: center;
  &.is-checked {
    text-decoration: line-through;
    opacity: 0.6;
    .checkbox-circle {
      background: var(--sidebar-text);
    }
  }
}

.checkbox-circle {
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  margin-right: 0.4rem;
  border-radius: 50%;
  background: var(--background);
  border: 2px solid var(--sidebar-text);
}

.overview-actions {
  line-height: 1.6;
  a {
    display: inline-flex;
    margin: 0.2rem 0;
    padding: 0.1rem 0;
    &:hover {
      opacity: 0.7;
    }
  }
}

.overview-actions-item {
  display: flex;
  align-items: center;
  &.with-dash {
    &:before {
      content: '';
      display: inline-flex;
      width: 14px;
      height: 2px;
      pointer-events: none;
      margin-right: 0.5rem;
      background: var(--sidebar-text);
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  width: 270px;
  background: var(--background);
  border-right: 1px solid var(--background_a3);
  &.wide {
    width: 320px;
  }
}

.list-header {
  display: flex;
  align-items: center;
  padding: 1.8rem 1.0rem 1.0rem 3.6rem;
  justify-content: space-between;
  border-bottom: 1px solid var(--background_a3);
  height: var(--view-header-height);

  &.with-nav {
    padding-left: 1.6rem;
  }

  h2 {
    display: inline-flex;
    align-items: flex-end;
  }
}

.list-header-toggle-button {
  font-size: 1.0rem;
}

.list-header-button {
  border: 0;
  background: none;
  box-shadow: none;
  padding: 3px 6px;
  color: var(--accent);
  border: 1px solid var(--accent);
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.list-body {
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
  padding: 0;
  background: var(--background);
  &.with-editing-locale-select {
    max-height: calc(100vh - var(--view-header-height) - var(--editing-locale-select-height));
  }
}

.list-item {
  display: block;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--background_a5);

  &.is-active {
    background: var(--background_a5);
    color: var(--accent);
  }

  &.is-active {
    background: var(--background_a3);
  }

  &:hover {
    opacity: 0.7;
  }

  a {
    display: block;
    padding: 0.8rem 1.6rem;
  }
}

.property-label {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  opacity: 0.6;
}

.property-value {
  margin-top: 0.5rem;
  .select-button-group {
    margin-right: 0.5rem;
  }
}

// padded
.is-padded .list-header {
  padding-left: 1.6rem;
}

.nav-editing-locale-select {
  opacity: 0;
  visibility: hidden;
  transition: all 120ms ease;
  transform: scaleY(0.9);
  &.is-active {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
  }
}

.nav-editing-locale-select.is-nested {
  padding: 1.0rem;
}

.is-padded .nav-editing-locale-select:not(.is-nested) {
  transform: translateX(var(--menu-width));
}

/* Tasks
 * ************ */
.task-list {
  padding: 0;
}

.task-list-header {
  display: flex;
  align-items: center;
  padding: 0.1rem 1.0rem;
  h3 {
    margin: 0;
  }
  a {
    margin-left: 1.0rem;
  }
}

.task-list-wide-item {
  display: flex;
  flex-direction: column;
  margin: 0.4rem 0;
  padding: 0.4rem 1.0rem;
  border-radius: 5px;
  background: var(--background_a3);
}

.task-list-wide-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0;
}

.task-list-wide-item-type {
}

.task-list-wide-item-status {
  opacity: 0.7;
  margin: 0;
}

.task-list-wide-item-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0;
  button {
    padding: 0;
    margin: 0;
    font-size: 0.875rem;
    & + button {
      margin-left: 0.8rem;
    }
  }
}

.task-list-item {
  display: block;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0.8rem 1.0rem 0.8rem 1.6rem;
  border-bottom: 1px solid var(--background_a5);

  &.is-active {
    background: var(--background_a5);
    color: var(--accent);
  }
}

.task-list-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
}

.task-list-item-header-title {
}

.task-list-item-header-status {
}

.task-list-item-status {
  border: 1px solid var(--background);
  padding: 2px 5px;
  font-size: 9px;
  border-radius: 5px;
  &.PROCESSED {
    color: var(--processed-color);
    border-color: hsla(120, 61.57%, 44.9%, 0.3);
  }
  &.PROCESSING {
    color: var(--processing-color);
    border-color: hsla(60, 84.33%, 43.4%, 0.3);
  }
  &.READY,
  &.CREATED {
    color: hsl(60, 0.33%, 43.4%);
    border-color: hsla(60, 0.33%, 43.4%, 0.3);
  }
}

.task-list-item-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0.6;
}

.item-view-translation {
}

.item-view-chapters {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100%;
}

.item-view-chapter {
  display: block;
  text-align: left;
  padding: 1.0rem 1.2rem;
  line-height: 1.6;

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  ul,
  ol {
    text-align: left;
    color: var(--text);
  }
}

.danger-zone {
  border: 1px solid hsla(360deg, 100%, 50%, 0.3);
  background: hsla(360deg, 100%, 50%, 0.1);
  margin: 0;
  padding: 1.2rem 1.2rem;
  border-radius: 6px;
}

.danger-button,
.danger-zone button {
  cursor: pointer;
  background: var(--background_a3);
  padding: 0.5rem 0.8rem;
  border: 1px solid var(--background_a5);
  color: hsla(360deg, 100%, 50%, 0.9);
  font-size: 15px;
}

.property-item {
  & + .property-item {
    margin-top: 1.5rem;
  }
}

.cta-link {
  border-bottom: 1px solid var(--sidebar-text);
  color: var(--sidebar-text);
  padding: 0.1rem 0;
  &:hover {
    opacity: 0.8;
  }
}

.plain-button {
  border: 1px solid var(--text_d5);
  color: var(--text);
  border-radius: 5px;
  font-size: 15px;
  padding: 0.5rem 0.7rem;
  &:hover {
    opacity: 0.8;
  }
}

.feedback-button-wrapper {
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 2;
}

.react-joyride__tooltip {
  a {
    padding-bottom: 2px;
    border-bottom: 1px solid;
  }
}

.aside-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.aside-body {
  flex: 1;
}

.aside-footer {
}

.cta-button {
  background: var(--sidebar-text);
  border: 1px solid var(--sidebar-background);
  color: var(--sidebar-background);
  border-radius: 5px;
  font-size: 15px;
  padding: 0.5rem 0.7rem;
  & + .cta-button-caption {
    margin-left: 1.0rem;
  }
}

button {
  & + .cta-button {
    margin-left: 0.5rem;
  }
}

hr {
  border-color: var(--background_a3);
}

// sonner
[data-sonner-toaster] {
  --normal-bg: var(--sidebar-background) !important;
  --normal-border: var(--background_a5) !important;
  --normal-text: var(--sidebar-text) !important;

  --success-bg: hsl(143, 85%, 96%) !important;
  --success-border: hsl(145, 92%, 91%) !important;
  --success-text: hsl(140, 100%, 27%) !important;

  --error-bg: hsl(359, 100%, 97%) !important;
  --error-border: hsl(359, 100%, 94%) !important;
  --error-text: hsl(360, 100%, 45%) !important;
}

.text-align-center {
  text-align: center;
}

.input-label {
  display: block;
  margin-bottom: 0.5rem;
}

.sectionTitle {
  margin: 1.8rem 0 1.0rem;
  padding: 0 1.5rem 0;
  font-size: 0.6875rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}

.sectionBody {
  padding: 0 1.0rem;
}

