.avatarSelectWrapper {
  flex: 1;
  max-width: 460px;
  padding: 0 0;
  margin: 0 0 1.0rem;
  & > label {
    display: block;
    margin-bottom: 1.0rem;
  }
}
