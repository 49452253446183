.ui-controls {
  display: flex;
  align-items: center;
  margin: 0rem 0 0.1rem;
  padding: 0.1rem 0.1rem 0.1rem;
  border-radius: 5px;
  transition: all 120ms ease;

  &.is-fixed {
    position: fixed;
    bottom: 0rem;
    left: 0rem;
    z-index: 7;
    width: 240px;
    padding: 1.0rem;
    justify-content: center;
    right: 0;
    width: auto;

    a,
    button {
      position: relative;
      color: var(--text);
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;
      height: 2.0rem;
      min-width: 2.0rem;
      font-size: 0.875rem;
      background: var(--background);
      border-radius: 0.25rem;
      border: 1px solid var(--background_a5);
      cursor: pointer;
      & > svg {
        width: 18px;
        margin: 0 0 0 0.1rem;
      }
    }
  }

  .select-wrapper {
    width: 100%;
    max-width: 180px;
    margin-right: 0.8rem;
    .selection {
      min-width: 128px;
      padding-left: 0.6rem;
      svg {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  a,
  button {
    display: flex;
    position: relative;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid transparent;
    &:hover {
      opacity: 1.0;
      color: var(--sidebar-text);
    }
    svg {
      width: 18px;
      margin-left: -0.2rem;
      fill: currentColor;
      margin-right: 0.5rem;
    }
  }
  #themes button,
  .help-link {
    padding: 0.3rem 0.3rem;
  }
}
