.member-list-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0;
  margin: 0 0 1.0rem;
  border-bottom: 1px solid var(--border-color);
  &--inactive {
    opacity: 0.5;
  }
}

.member-list-info {
  display: flex;
  align-items: center;
  img {
    width: 2.0rem;
    height: 2.0rem;
    border-radius: 50%;
  }
}

.member-list-IDs {
  margin-left: 0.8rem;
  strong {
    margin: 0;
    font-weight: 500;
    font-size: 0.9375rem;
  }
  p {
    margin: 0.3rem 0 0;
    opacity: 0.7;
    font-size: 0.875rem;
  }
}

.member-list-access {
  display: flex;
  align-items: center;
}

.member-list-status {
  margin: 0 0.4rem;
  font-size: 0.875rem;
  opacity: 0.7;
}

.member-list-role {
  margin: 0 0.4rem;
  font-size: 0.875rem;
}
