.content {
  display: flex;
  flex-direction: column;
  background: var(--background);
}

.head {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.0rem 1.5rem;
}

.body {
  flex: 1;
  display: flex;
  border-radius: 5px;
  margin: 2px 0;
  overflow-y: auto;
}
