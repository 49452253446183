.onboardingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: hsla(0, 0%, 0%, 0.5);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  z-index: 8;
}

.onboardingWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;

  max-width: 860px;
  min-height: 300px;
  max-height: 800px;

  margin: 0 auto;
  cursor: default;
  overflow-y: auto;

  background: var(--background);
  border: 1px solid var(--sidebar-background);
  border-radius: 4px;
  box-shadow: 0 40px 28px hsla(240, 0%, 0%, 0.03),
              0 12px 12px hsla(240, 0%, 0%, 0.015),
              0 5px 10px hsla(0, 0%, 0%, 0.01);
}

.onboardingContent {
  flex: 1;
  width: 100%;
  max-width: 1080px;
  max-height: 880px;
  display: flex;
  flex-direction: column;
  background: var(--background);
}

.onboardingHead {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.0rem 1.5rem;
}

.onboardingProgress {
  padding: 0.4rem 1.0rem;
  button {
    margin: 0 0.3rem;
    padding: 0.3rem 0.4rem;
    border: 1px solid var(--background);
    border-radius: 5px;
    font-size: 14px;
    transition: all 100ms ease;
    &:global {
      &.is-active {
        color: var(--sidebar-text);
        border-color: currentColor;
      }
    }
    @media (min-width: 720px) {
      padding: 0.4rem 0.6rem;
      margin: 0 0.4rem;
      font-size: 15px;
    }
  }
}

.onboardingBody {
  flex: 1;
  display: flex;
  border-radius: 5px;
  margin: 0 1.0rem;
  overflow-y: auto;
}

.onboardingFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2.4rem;
  font-size: 14px;
  opacity: 0.6;
}
