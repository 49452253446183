.settingsOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: hsla(0, 0%, 0%, 0.3);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);

  opacity: 0;
  visibility: hidden;

  &:global {
    &.active {
      z-index: 8;
      opacity: 1.0;
      visibility: visible;
    }
  }
}

.settings {
  flex: 1;
  display: flex;
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 30px;
  z-index: 1;

  max-width: 960px;
  max-height: 720px;

  margin: auto;
  cursor: default;

  background: var(--background);
  border: 1px solid var(--sidebar-background);
  border-radius: 4px;
  box-shadow: 0 40px 28px hsla(240, 0%, 0%, 0.03),
              0 12px 12px hsla(240, 0%, 0%, 0.015),
              0 5px 10px hsla(0, 0%, 0%, 0.01);

  opacity: 0;
  visibility: hidden;

  @media (min-width: var(--medium-breakpoint)) {
    max-width: 800px;
  }

  @media (min-width: var(--wide-breakpoint)) {
    max-width: 800px;
  }

  @media (min-width: var(--widest-breakpoint)) {
    max-width: 800px;
  }

  &:global {
    &.active {
      z-index: 8;
      opacity: 1.0;
      transform: scale(1.0);
      visibility: visible;
    }
    &.preview {
      @media (min-width: var(--wide-breakpoint)) {
        right: 0;
        padding-right: 3.0rem;
      }
    }
  }
}

.body {
  display: block;
  padding: 1.2rem 1.0rem;
  width: 100%;
  max-width: 360px;
  &:global {
    &.billing {
      padding: 1.2rem 1.0rem;
      max-width: 100%;
    }
    &.profile {
      @media (min-width: 760px) {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;
        max-width: 100%;
      }
    }
  }
}

.settingsBody {
  display: flex;
  flex: 1;
  width: 100%;
  padding: 0 0;
  padding-left: var(--secondary-menu-width);
  overflow-y: auto;
}

.pageWrapper {
  position: relative;
  display: flex;
  flex: 1;
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
  padding: 1.5rem 1.0rem;

  @media (min-height: 720px) {
    padding-top: 6vh;
  }
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.avatarSelectWrapper {
  flex: 1;
  max-width: 240px;
  padding: 0 0;
  margin: 0 0 1.0rem;
}

.inner {
  padding: 0;
  width: 100%;
  order: -1;
}

.form {
  position: relative;
  margin: 0 0;
}

.fieldset {
  position: relative;
  width: 100%;
  margin: 0 auto 1.4rem;

  label {
    padding: 0 0.8rem;
    & + input,
    & + textarea {
      margin-top: 0.4rem;
    }
  }

  svg {
    position: absolute;
    top: 2.0rem;
    left: 0;
    width: 0.875rem;
    height: 0.875rem;
    margin: 0.3rem 0.7rem;
    pointer-events: none;
    opacity: 0.75;
    * {
      fill: currentColor;
    }
  }

  .before {
    font-size: 1.0rem;
    font-weight: 400;
    padding: 0.5rem 0.6rem;
    background: hsla(0, 0%, 50%, 0.1);
    border: 1px solid hsla(213, 11%, 50%, 0.2);
    border-right: 0;
    border-radius: 3px 0 0 3px;
    color: hsla(0, 0%, 50%, 1.0);
    & + input {
      border-radius: 0 3px 3px 0;
    }
  }

  textarea {
    min-height: 6.4rem;
    resize: none;
  }

  &.with-icon input {
    padding-left: 2.0rem;
  }
}

.button {
  display: block;
  margin: 0 0.4rem 0 0;
  padding: 0.3rem 0.6rem;

  font-size: 0.875rem;
  font-weight: 500;

  user-select: none;

  border: 1px solid var(--sidebar-background);
  border-radius: 3px;
  appearance: none;

  &[disabled] {
    opacity: 0.6;
    cursor: default;
  }

  &:global {
    &.cancelButton {
      background-color: transparent;
      color: var(--text);
    }

    &.saveButton {
      border: 1px solid var(--accent);
      color: var(--accent);
    }
  }
}

.title {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
}

.subtitle {
  font-size: 0.9325rem;
  font-weight: 400;
}

.headline {
  padding: 1.0rem 0;
}

.header {
  padding: 0.6rem 1.0rem 0.6rem;
  border-bottom: 1px solid var(--background_a5);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerToolbar {
  display: flex;
  align-items: center;
  margin-bottom: -1px;
}

.headerToolbarLink {
  display: flex;
  align-items: center;
  margin: 0 0.4rem;
  padding: 1.0rem 0.2rem;
  font-size: 0.875rem;
  border-bottom: 1px solid transparent;

  &.active {
    color: var(--action-color);
    border-bottom-color: currentColor;
  }

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }

  svg {
    width: 0.875rem;
  }
}

.footer {
  display: flex;
  padding: 1.0rem 0;
  border-top: 1px solid var(--sidebar-background);
  border-radius: 0 0 3px 3px;
}

.vertical {
  display: flex;
  flex-direction: column;
}

.closeButton {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  z-index: 5;
  padding-top: 1px;
  border-radius: 50%;
  background: var(--background-color);
  border: 1px solid var(--sidebar-background);
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  transition: all 100ms ease;
  color: var(--color);
  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 1rem;
    height: 1rem;
    fill: currentColor;
  }
  &:global {
    &.internal {
      top: 1.5rem;
      left: 1.5rem;
      border-color: transparent;
      background: hsla(229.29, 0%, 50%, 0.15);
    }

    &.preview {
      top: 0.7rem;
      left: 1.5rem;
      border-color: transparent;
      background: hsla(229.29, 0%, 50%, 0.15);
    }

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }
}

.langSelect {
  position: absolute;
  margin-top: -0.4rem;
  right: 1.0rem;
  z-index: 3;
}
