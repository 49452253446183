.plan-switch {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.plan-head {
  padding: 1.2rem 1.5rem 1.0rem;
}

.plan-current-caption {
  margin: 0;
  line-height: 1.6;
  a {
    margin: 0 0 0 6px;
    padding: 0;
    color: var(--sidebar-text);
  }
}

.plan-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0rem 0 1.0rem;
  padding: 0.4rem 1.5rem;
  border-radius: 5px;
  background: var(--background_a3);
}

.plan-list-copy {
  line-height: 1.6;
  font-size: 1.0rem;
  margin: 0.4rem 0 1.5rem;
  padding: 0;
  p {
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
    }
  }
  ul {
    margin: 0;
    line-height: 1.2;
    padding: 0;
    list-style-position: inside;
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 0.6rem;
    @media (min-width: 720px) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 0.8rem;
      grid-row-gap: 0.7rem;
    }
  }
}

.plan-list-head {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plan-list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.0rem;
  background: var(--background_a5);
  padding: 1.0rem;
  border-radius: 5px;
  h2 {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }
  ul {
    margin: 0.2rem 0 1.0rem;
    line-height: 1.5;
    padding: 0;
    list-style-position: inside;
  }
}

.plan-list-item-label {
  display: block;
  margin-top: 0.8rem;
  font-size: 15px;
  text-transform: uppercase;
  opacity: 0.6;
}


.plan-list-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0em 0;
  font-size: 15px;
}

.plan-list-items {
  flex: 1;
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  @media (min-width: 720px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.plan-list-item-body {
  flex: 1;
  line-height: 1.5;
  margin: 0;
}

.plan-list-item-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plan-list-item-for {
  position: absolute;
  top: 1.0rem;
  right: 1.0rem;
  z-index: 2;
}

.plan-list-item-cta {
  display: flex;
  align-items: center;
  button {
    margin-right: 0.6rem;
    background: var(--sidebar-text);
    color: var(--sidebar-background);
    padding: 0.3rem 0.6rem;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
  }
}

.plan-list-item-price {
  padding: 0;
  margin: 1.0rem 0 0;
  font-size: 20px;
  font-weight: 600;
  small {
    margin-left: 0.5rem;
    font-weight: 500;
    font-size: 0.7em;
  }
}

