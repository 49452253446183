.toggleButton {
  position: fixed;
  top: -2px;
  left: 1.0rem;
  z-index: 6;
  width: 1.8rem;
  height: 1.4rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--sidebar-background);
  box-shadow: none;
  border: 1px solid var(--sidebar-background_a5);
  border-radius: 0 0 5px 5px;
  cursor: pointer;
  transition: all 120ms ease;

  opacity: 0;
  visibility: hidden;
  transform: scale(0.9) translateX(0);

  &:global {
    &.is-padded {
      transform: scale(1) translateX(calc(240px - 1.8rem));
    }
    &.is-active {
      opacity: 1.0;
      visibility: visible;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.menuLines {
  display: flex;
  position: relative;
  width: 1.0rem;
  height: 1.0rem;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1.5px;
    width: 100%;
    background: var(--sidebar-text);
    height: 2px;
    transition: all 200ms ease;
    transform-origin: 50% 50%;
  }

  &:before {
    transform: translateY(-3px) rotate(0deg);
  }

  &:after {
    transform: translateY(3px) rotate(0deg);
  }

  &.active {
    &:before {
      transform: translateY(0rem) rotate(45deg);
    }

    &:after {
      transform: translateY(0rem) rotate(-45deg);
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  padding: 1.0rem 0 0.7rem;
  width: 240px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  overflow-y: auto;
  background: var(--sidebar-background_a1);
  border-right: 1px solid var(--background_a3);
  transform: translateX(-100%);
  transition: all 120ms ease;
  -webkit-backdrop-filter: blur(8px);

  &:global {
    &.is-visible {
      transform: translateX(0);
    }
  }
}

.listItem {
  margin: 0rem 0 0.1rem;
  padding: 0.1rem 0.1rem 0.1rem;
  border-radius: 5px;
  transition: all 120ms ease;
  &:global {
    &.is-active {
      a, button {
        color: var(--accent);
        border-color: var(--accent);
      }
    }
  }
  :global {
    .processing-count {
      position: absolute;
      right: 0.6rem;
      color: var(--processed-color);
    }
  }
  a,
  button {
    display: flex;
    position: relative;
    padding: 0.3rem 0.6rem;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid transparent;
    &:hover {
      opacity: 1.0;
      color: var(--sidebar-text);
    }
  }
  a {
    svg {
      margin-top: 2px;
    }
  }
  svg {
    width: 18px;
    margin-left: -0.2rem;
    fill: currentColor;
    margin-right: 0.5rem;
  }
  &:global {
    &.small {
      margin: 0;
      a {
        font-size: 13px;
      }
    }
    &.trial {
      margin-bottom: -4px;
      a {
        display: block;
        padding: 0.4rem 0.6rem;
        background: var(--sidebar-background);
        border: 1px solid var(--sidebar-text);
        color: var(--sidebar-text);
        cursor: pointer;
        small {
          display: block;
          margin: 3px 0;
          font-weight: 500;
          font-size: 0.8em;
          text-transform: uppercase;
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &:global {
    &.coming-soon {
      position: relative;
      cursor: default;
      opacity: 0.6;
      pointer-events: none;
      .label {
        display: inline-block;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -0.9em;
        font-size: 10px;
        border: 1px solid hsla(0, 0%, 50%, 0.4);
        border-radius: 5px;
        padding: 3px 5px;
      }
    }
  }
}

.listItemLinks {
  padding: 0rem 0 0.3rem 0.4rem;
  a {
    display: flex;
    margin: 5px 0;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    color: var(--text);
    font-size: 13px;
    &:hover {
      color: var(--accent);
    }
  }
}

.listItemLinksItem {
  &:global {
    &.is-active {
      & > a {
        opacity: 0.7;
      }
    }
  }
}

.appBlock {
  margin: 0 0 0.5rem;
  a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
  }
}

.block {
  padding: 1rem 0 0.5rem;
  &:global {
    &.controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0.2rem 1.2rem 0.8rem;
      margin: 0 0.2rem;
      // border-bottom: 1px solid hsla(0, 0%, 50%, 0.2);
    }
    &.head {
      display: flex;
      align-items: center;
      padding: 0.6rem 1.4rem;
      margin: 0 0.0rem;
      border-bottom: 1px solid var(--background_a3);
    }
    &.body {
      flex: 1;
      padding: 0 0.7rem 0;
      overflow-y: auto;
    }
    &.footer {
      padding: 0.6rem 0.7rem 0;
      margin: 0 0;
      border-top: 1px solid var(--background_a3);
      opacity: 0.7;
      transition: all 180ms ease;
      &:hover {
        opacity: 1.0;
      }
    }
  }
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

.blockHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.8rem;
  h4 {
    margin: 0;
    cursor: default;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--accent);
    opacity: 0.8;
  }
}

.blockHeaderButton {
  position: relative;
  z-index: -1;
  width: 1.4rem;
  height: 1.4rem;
  color: var(--accent);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border: 1px solid var(--accent);
  border-radius: 5px;
  background: none;
  box-shadow: none;
  cursor: pointer;
  svg {
    position: absolute;
    z-index: 1;
    margin: auto;
    display: block;
    width: 1.0rem;
    height: 1.0rem;
    fill: currentColor;
  }
}

.blockBody {
  padding: 0.6rem 0 1.0rem;
}

.toggleMinimalViewButton {
  position: fixed;
  top: -2px;
  left: 1.0rem;
  z-index: 8;
  width: 1.8rem;
  height: 1.4rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--sidebar-background);
  box-shadow: none;
  border: 1px solid var(--sidebar-background_a5);
  border-radius: 0 0 5px 5px;
  cursor: pointer;
  transition: all 120ms ease;

  transform: translateX(calc(240px - 1.8rem));

  svg {
    width: 1rem;
    color: var(--sidebar-text);
    fill: currentColor;
  }

  &:global {
    &.is-minimal-view {
      transform: translateX(0);
    }
    &.is-extra {
      transform: translateX(calc(480px - 1.8rem));
    }
    &.is-visible {
      opacity: 1.0;
      visibility: visible;
    }
    &.is-padded {
    }
  }
}
