.auth-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 7;
  background: hsla(0, 0%, 0%, 0.3);
  transition: all 340ms ease;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);

  opacity: 0;
  visibility: hidden;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }
}

.auth-window {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 7;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  margin: 0 0 1.8rem;
  line-height: 1.25;
  font-size: 1.4rem;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
}
